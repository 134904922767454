.wrapper {
	padding-top: 25px;
}

.wrapper .loadingWrapper {
	text-align: center;
}
.wrapper .loading {
	display: inline-block;
}

.actionButton {
	flex: 1 1 50%;
}