.key-value-list-component.compact-style {
	--key-value-list-border-radisu: var(--input-border-radisu);
	
	--key-value-list-labels-height: var(--input-height);
	--key-value-list-select-column-width: 40px;
	--key-value-list-item-spacing: 10px;
	--key-value-list-key-column-width: 40%;
	--key-value-list-key-column-min-width: 50px;
	--key-value-list-value-column-width: 60%;
	--key-value-list-value-column-min-width: 150px;
	--key-value-list-delete-btn-size: 36px;
	
	background: var(--form-control-background);
	border: var(--form-control-border);
	border-radius: var(--key-value-list-border-radisu);
	overflow: auto;
}

.key-value-list-component.compact-style .select-wrapper,
.key-value-list-component.compact-style > .labels > .key-label,
.key-value-list-component.compact-style > .labels > .value-label,
.key-value-list-component.compact-style > .bottom-toolbar {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
}


/* Define all borders */
.key-value-list-component.compact-style .form-control,
.key-value-list-component.compact-style .form-control .select-input__control:not(.select-input__control--is-focused) {
	border-color: transparent;
}
.key-value-list-component.compact-style .form-control:focus,
.key-value-list-component.compact-style .form-control .select-input__control.select-input__control--is-focused {
	
}
.key-value-list-component.compact-style .remove-wrapper .button-component,
.key-value-list-component.compact-style .remove-wrapper .button {
	border: none;
}
.key-value-list-component.compact-style .labels > div:first-of-type,
.key-value-list-component.compact-style .labels > div:last-of-type,
.key-value-list-component.compact-style .key-value-list-item-component > div:first-of-type,
.key-value-list-component.compact-style .key-value-list-item-component > div:last-of-type,
.key-value-list-component.compact-style .key-value-list-item-component > .key-value-wrapper > div:last-of-type,
.key-value-list-component.compact-style .key-value-list-item-component > .key-value-wrapper:first-of-type > div:first-of-type {
	border: none;
}
.key-value-list-component.compact-style .labels > div:not(:first-of-type),
.key-value-list-component.compact-style .key-value-list-item-component > div:not(.key-value-wrapper):not(:first-of-type),
.key-value-list-component.compact-style .key-value-list-item-component > .key-value-wrapper > div,
.key-value-list-component.compact-style .key-value-list-item-component > .key-value-wrapper > div:last-of-type {
	border-left: var(--form-control-border);
}
.key-value-list-component.compact-style > .toolbar {
	border-width: 0 0 1px;
	border-radius: var(--key-value-list-border-radisu) var(--key-value-list-border-radisu) 0 0;
}
.key-value-list-component.compact-style .labels {
	border-bottom: var(--form-control-border);
}
.key-value-list-component.compact-style .key-value-list-item-component {
	position: relative;
	border-bottom: var(--form-control-border);
}
.key-value-list-component.compact-style > .labels:last-of-type {
	border-bottom: none;
	border-bottom-left-radius: var(--input-border-radisu);
	border-bottom-right-radius: var(--input-border-radisu);
}
.key-value-list-component.compact-style > .labels.no-labels > .select-wrapper {
	border-right: var(--form-control-border);
}


/* Reset all margins */
.key-value-list-component.compact-style > .labels > .select-wrapper,
.key-value-list-component.compact-style .key-value-list-item-component,
.key-value-list-component.compact-style .key-value-list-item-component > div,
.key-value-list-component.compact-style .key-value-list-item-component .key-value-wrapper > div {
	margin: 0;
}


/* Define select checkbox */
.key-value-list-component.compact-style .select-wrapper {
	flex: 0 0 var(--key-value-list-select-column-width);
}
.key-value-list-component.compact-style .select-wrapper .checkbox-component {
	width: 100%;
	flex: 1 1 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	margin: 0;
	padding: 0;
	border-radius: 0;
	min-width: var(--key-value-list-select-column-width);
}
.key-value-list-component.compact-style .select-wrapper .checkbox-component:focus {
	border: 1px solid var(--action-color-main);
	outline: none;
}
.key-value-list-component.compact-style .select-wrapper .checkbox-component > .fa {
	text-align: center;
	padding-top: 1px;
}

/* Toolbar ---------------------------------------------------------------------------------------------------------- */
.key-value-list-component.compact-style > .toolbar.max-height {
	position: sticky;
	top: 0;
	z-index: 1;
}
.key-value-list-component.compact-style > .toolbar .button-component,
.key-value-list-component.compact-style > .toolbar .button {
	min-width: var(--key-value-list-select-column-width);
}


/* Labels ----------------------------------------------------------------------------------------------------------- */
.key-value-list-component.compact-style > .labels {
	display: flex;
	flex-flow: row nowrap;
	background: var(--table-head-background);
	height: var(--key-value-list-labels-height);
}
.key-value-list-component.compact-style > .labels.max-height {
	position: sticky;
	top: 37px;
	z-index: 1;
}
.key-value-list-component.compact-style > .labels > .key-label,
.key-value-list-component.compact-style > .labels > .value-label {
	padding: .375rem .5rem;
	line-height: 1.2;
	font-weight: bold;
}
.key-value-list-component.compact-style > .labels > .key-label {
	flex-basis: var(--key-value-list-key-column-width);
	min-width: var(--key-value-list-key-column-min-width);
}
.key-value-list-component.compact-style > .labels > .value-label {
	flex-basis: var(--key-value-list-value-column-width);
	min-width: var(--key-value-list-value-column-min-width);
}
.key-value-list-component.compact-style.removable > .labels > .value-label {
	margin-right: var(--key-value-list-delete-btn-size);
}


/* Main content ----------------------------------------------------------------------------------------------------- */
.key-value-list-component.compact-style > .form-wrapper {
	--input-border-radisu: 0;
	
	padding: 0;
}

.key-value-list-component.compact-style .key-value-list-item-component .key-value-wrapper {
	display: flex;
	flex-flow: row nowrap;
	flex: 1 1 100%;
}
.key-value-list-component.compact-style .key-value-list-item-component .key-wrapper {
	flex-basis: var(--key-value-list-key-column-width);
	min-width: var(--key-value-list-key-column-min-width);
}
.key-value-list-component.compact-style .key-value-list-item-component .value-wrapper {
	flex-basis: var(--key-value-list-value-column-width);
	min-width: var(--key-value-list-value-column-min-width);
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: center;
}
.key-value-list-component.compact-style .key-value-list-item-component .remove-wrapper {
	flex: 0 0 var(--key-value-list-delete-btn-size);
}
.key-value-list-component.compact-style .key-value-list-item-component .remove-wrapper .button-component,
.key-value-list-component.compact-style .key-value-list-item-component .remove-wrapper .button {
	width: 100%;
	height: 100%;
	margin: 0;
	padding-top: 0;
	padding-bottom: 0;
	border-radius: 0;
}
.key-value-list-component.compact-style .key-value-list-item-component .remove-wrapper .button-component:hover,
.key-value-list-component.compact-style .key-value-list-item-component .remove-wrapper .button:hover {
	background-color: var(--error-background);
	color: var(--error-text);
}
.key-value-list-component.compact-style .key-value-list-item-component .remove-wrapper .button-component:focus,
.key-value-list-component.compact-style .key-value-list-item-component .remove-wrapper .button:focus {
	border: 1px solid var(--action-color-main);
	outline: none;
}

.key-value-list-component.compact-style .key-value-list-item-component .key-value-wrapper .key-wrapper .form-field,
.key-value-list-component.compact-style .key-value-list-item-component .key-value-wrapper .value-wrapper .form-field {
	margin-bottom: 0;
}
.key-value-list-component.compact-style .key-value-list-item-component .form-field-input,
.key-value-list-component.compact-style .key-value-list-item-component .form-control-wrapper,
.key-value-list-component.compact-style .key-value-list-item-component .form-control {
	width: 100%;
	max-width: none;
}

.key-value-list-component.compact-style .key-value-list-item-component .form-control[readonly],
.key-value-list-component.compact-style .key-value-list-item-component .form-control.read-only {
	background-color: var(--form-control-label-background);
	color: var(--form-control-label-text);
	border-color: var(--form-control-label-background);
}


/* Bottom toolbar --------------------------------------------------------------------------------------------------- */
.key-value-list-component.compact-style > .bottom-toolbar {
	height: var(--key-value-list-labels-height);
	border-top: var(--form-control-border);
}
.key-value-list-component.compact-style > .bottom-toolbar .button-component,
.key-value-list-component.compact-style > .bottom-toolbar .button {
	margin: 0;
	height: 100%;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}


/* Responsive ------------------------------------------------------------------------------------------------------- */
@media screen and (max-width: 640px) {
	.key-value-list-component.compact-style .key-value-list-item-component .key-value-wrapper {
		flex-flow: row wrap;
		gap: 0;
	}
	.key-value-list-component.compact-style .key-value-list-item-component .key-value-wrapper > div {
		flex: 0 0 100%;
		margin-right: 0;
		border-left: none !important;
	}
	.key-value-list-component.compact-style .key-value-list-item-component .key-value-wrapper:not(:first-of-type) {
		border-left: var(--form-control-border);
	}
	.key-value-list-component.compact-style .key-value-list-item-component .key-value-wrapper .key-wrapper {
		border-bottom: var(--form-control-border) !important;
		border-bottom-style: dashed !important;
	}

	.key-value-list-component.compact-style .key-value-list-item-component .form-control[readonly],
	.key-value-list-component.compact-style .key-value-list-item-component .form-control.read-only {
		background-color: var(--form-control-background);
		color: var(--form-control-text);
		border-color: var(--form-control-background);
	}

	.key-value-list-component.compact-style.selectable > .labels > .key-label,
	.key-value-list-component.compact-style.selectable > .labels > .value-label,
	.key-value-list-component.compact-style:not(.selectable) > .labels {
		display: none;
	}
	.key-value-list-component.compact-style > .labels > .select-wrapper {
		border-right: var(--form-control-border) !important;
	}
}