.wrapper .innerWrapper {
	justify-content: center;
}
.wrapper .fileColumnMap {
	--input-height: none;
	--input-font-size: 12px;
	--key-value-list-labels-height: 30px;
	--key-value-list-key-column-width: 40%;
	--key-value-list-key-column-min-width: 100px;
	--key-value-list-value-column-width: 61%;
	--key-value-list-value-column-min-width: 150px;

	border-radius: 0;
}
.wrapper .fileColumnMap input {
	padding: .25rem .5rem;
}
.wrapper .fileColumnMapKey {
	background-color: var(--form-control-label-background);
	color: var(--form-control-label-text);
}
.wrapper .fileActions {
	display: flex;
	flex-flow: row wrap;
	gap: 5px;
	justify-content: space-between;
	align-items: center;
	padding: 5px;
	border-top: var(--form-control-border);
}
.wrapper .fileActions .overrideCustomer {
	font-size: 12px;
}
.wrapper .fileActions button {
	font-size: 12px;
}
.wrapper .fileErrors {
	margin-top: 5px;
}
.wrapper .fileError {
	font-size: 12px;
	padding: .5em .75em;
	margin-bottom: 1px;
}
.wrapper .fileError strong {
	font-weight: bold;
}
.wrapper .fileError:last-child {
	margin-bottom: 0;
}

.wrapper .innerWrapper .uniqueIdColumn {
	margin-bottom: 0 !important;
}
.wrapper .innerWrapper .uniqueIdColumn .uniqueIdColumnLabel {
	padding: .375rem .5rem 0;
	line-height: 1.2;
	font-weight: bold;
	background: var(--table-head-background);
	width: 100%;
	margin-bottom: 0 !important;
	border-top: var(--form-control-border);
	/*border-bottom: var(--form-control-border);*/
}
.wrapper .innerWrapper .uniqueIdColumn .uniqueIdColumnInput {
	--form-control-border: none;
}

@media screen and (max-width: 640px) {
	.wrapper .fileColumnMap {
		border-top: var(--form-control-border) !important;
		border-top-width: 1px !important;
		border-radius: 0 !important;
	}
	.wrapper .fileActions {
		border-top-width: 2px;
	}
}

@media screen and (max-width: 480px) {
	.wrapper .fileActions button {
		width: 100%;
		font-size: 1rem;
	}
}