.wrapper {
	--arrow-size: 16px;
	--arrow-color: var(--popup-background-secondary);
	--background-color: var(--popup-background);
	--settings-background-color: var(--popup-background-fade);
}

.wrapper .list {

}

.wrapper .item {

}
.wrapper .item .channelRow {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.wrapper .item .mainRow {

}
.wrapper .item .fallbackRow {

}
.wrapper .item .fallbackRow.fallbackRest {

}
.wrapper .item .fallbackRow.fallbackFirst {

}

.wrapper .item .main,
.wrapper .item .fallback {
	margin-bottom: 2em;
	width: 100%;
}

.wrapper .item .channelSettingsBtn {
	border-left: var(--form-control-border);
	margin: 0;
}
.wrapper .item .channelSettingsBtn.opened {
	box-shadow: 0 2px 0 0 var(--settings-background-color);
}
.wrapper .item .channelSettingsBtn.opened:not(.error) {
	background-color: var(--settings-background-color);
}
.wrapper .item .main .channelSettingsBtn.opened {
	border-top-right-radius: var(--input-border-radisu);
}

.wrapper .item .channelSettingsInfo {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	padding: calc(.25em + (var(--input-border-radisu) / 2)) .5em .25em;
	background-color: var(--settings-background-color);
	border: var(--form-control-border);
	border-top: none;
	margin-top: calc(0px - (var(--input-border-radisu) / 2));
	border-bottom-left-radius: var(--input-border-radisu);
	border-bottom-right-radius: var(--input-border-radisu);
}
.wrapper .item .channelSettingsInfo > i {
	color: var(--icon-action-color);
}
.wrapper .item .channelSettingsInfo:hover > i {
	color: var(--icon-action-color-hover);
}

.wrapper .item .channelSettings {
	padding: calc(1em + (var(--input-border-radisu) / 2)) 1em 1em;
	background-color: var(--settings-background-color);
	border: var(--form-control-border);
	border-top: none;
	margin-top: calc(0px - (var(--input-border-radisu) / 2));
	border-bottom-left-radius: var(--input-border-radisu);
	border-bottom-right-radius: var(--input-border-radisu);
}
.wrapper .item .channelSettings.error {
	border-color: var(--error-background);
}

.wrapper.readOnly .item .main,
.wrapper.readOnly .item .fallback {
	margin-bottom: .5em;
}
.wrapper.readOnly .item .main > *:first-child,
.wrapper.readOnly .item .fallback > *:first-child {
	padding: calc((var(--input-height) / 2) - 0.5em);
}
.wrapper.readOnly .item .main {
	background-color: var(--highlight-color);
	color: var(--highlight-color-contrast);
}
.wrapper.readOnly .item .fallback {
	background-color: var(--arrow-color);
}
.wrapper .item:last-of-type .fallback {
	margin-bottom: 0;
}

.wrapper .item .fallbackRow > div:last-of-type {
	border-left: var(--arrow-size) solid var(--arrow-color);
	padding-left: 2em;
	position: relative;
}
.wrapper.readOnly .item .fallbackRow > div:last-of-type {
	/*padding-left: .5em;*/
}
.wrapper .item:last-of-type .fallbackRow > div:last-of-type {
	margin-bottom: 1em;
}
.wrapper.readOnly .item:last-of-type .fallbackRow > div:last-of-type {
	margin-bottom: calc(var(--arrow-size) / 2);
}
.wrapper .item .fallbackRow.fallbackFirst > div:last-of-type:before {
	content: "";
	width: 0;
	height: 0;
	position: absolute;
	top: 0;
	left: calc(0px - (var(--arrow-size)));
	border-left: calc(var(--arrow-size) / 2) solid var(--arrow-color);
	border-right: calc(var(--arrow-size) / 2) solid var(--arrow-color);
	border-top: calc(var(--arrow-size) / 2) solid var(--background-color);
	z-index: 1;
}
.wrapper .item:last-of-type .fallbackRow > div:last-of-type:after {
	content: "";
	width: 0;
	height: 0;
	position: absolute;
	bottom: 0;
	left: calc(0px - (var(--arrow-size)));
	border-left: calc(var(--arrow-size) / 2) solid var(--background-color);
	border-right: calc(var(--arrow-size) / 2) solid var(--background-color);
	border-top: calc(var(--arrow-size) / 2) solid var(--arrow-color);
}
.wrapper.readOnly .item .channel.withContentPreviewBtn {
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
}
.wrapper.readOnly .item .channel.withContentPreviewBtn > *:first-child {
	flex: 1;
}
.wrapper.readOnly .item .channel.withContentPreviewBtn .contentPreviewBtnTooltip {
	display: block !important;
	flex: 0 0 auto;
}
.wrapper.readOnly .item .contentPreviewBtnTooltip > button {
	color: inherit;
	height: 100%;
	margin: 0;
	padding: 0.5em 1em;
}
.wrapper.readOnly .item .contentPreviewBtnTooltip > button:hover {
	box-shadow: var(--hover-box-shadow);
}
.wrapper.readOnly .item .channel .legend {
	flex: 0 0 100%;
	font-size: .75em;
	line-height: 1.1;
	color: var(--text-color-faded);
	padding: calc((var(--input-height) / 2) - 0.5em);
	border-top: 1px solid rgba(var(--card-shadow-rgb), 0.2);
	box-shadow: inset 0 0 0 100px rgba(var(--card-shadow-rgb), 0.05);
}
.wrapper.readOnly .item .channel.main .legend {
	color: var(--highlight-color-contrast-faded);
	box-shadow: inset 0 0 0 100px rgba(var(--card-shadow-rgb), 0.075);
}

.wrapper .actions {
	margin-top: -.5em !important;
	margin-bottom: 1px !important;
}
.wrapper .actions > div:last-of-type button {
	margin: 0;
	width: 100%;
	text-align: center;
	border-style: none;
	padding: .75em 0;
	font: var(--font-main);
	font-size: .875rem;
}
.wrapper .actions > div:last-of-type button:not(:disabled):hover {
	box-shadow: inset 0 0 0 999em var(--table-row-highlight-background);
	transition: all 0.1s ease-in;
}

@media screen and (max-width: 640px) {
	.wrapper .item .channelRow {
		margin-bottom: 0 !important;
	}
	.wrapper .item .mainRow {
		margin-bottom: 1rem !important;
	}
	.wrapper .item .channelRow > div:first-of-type {
		margin-bottom: .5rem !important;
	}
	.wrapper .item .channelRow.fallbackRest > div:first-of-type,
	.wrapper .actions > div:first-of-type {
		display: none !important;
	}

	.wrapper .actions > div:last-of-type button {
		width: 100%;
	}

	.wrapper.readOnly .item .channel.withContentPreviewBtn .contentPreviewBtnTooltip span {
		display: none;
	}
	.wrapper.readOnly .item .channel.withContentPreviewBtn .contentPreviewBtnTooltip i {
		margin: 0 !important;
	}
}