.f-left { float: left !important; }
.f-right { float: right !important; }
.f-clear { clear: both !important; }
.full-width { width: 100% !important; }
.max-full-width {max-width: 100% !important; }
.half-width { width: 50% !important; }
.max-half-width { max-width: 50% !important; }
.text-right { text-align: right !important; }
.text-left { text-align: left !important; }
.text-center { text-align: center !important; }
.bold { font-weight: bold !important; }

.text-upper { text-transform: uppercase !important; }
.text-lower { text-transform: lowercase !important; }

.sup { vertical-align: super; font-size: 0.7em; }
.sub { vertical-align: sub; font-size: 0.7em; }

.no-display { display: none !important; }
.no-padding { padding: 0 !important; }
.no-t-padding { padding-top: 0 !important; }
.no-r-padding { padding-right: 0 !important; }
.no-b-padding { padding-bottom: 0 !important; }
.no-l-padding { padding-left: 0 !important; }
.no-wrap { white-space: nowrap !important; }
.no-margin { margin: 0 !important; }
.no-border-radius { border-radius: 0 !important; }
.max-height { height: 100% !important; }

.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently not supported by any browser */
}

.flipH {
	-moz-transform: scale(-1, 1);
	-webkit-transform: scale(-1, 1);
	-o-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	transform: scale(-1, 1);
}
.flipV {
	-moz-transform: scale(1, -1);
	-webkit-transform: scale(1, -1);
	-o-transform: scale(1, -1);
	-ms-transform: scale(1, -1);
	transform: scale(1, -1);
}
.rotate0 { transform: rotate(0deg) !important; }
.rotate45 { transform: rotate(45deg) !important; }
.rotate90 { transform: rotate(90deg) !important; }
.rotate135 { transform: rotate(135deg) !important; }
.rotate180 { transform: rotate(180deg) !important; }
.rotate225 { transform: rotate(225deg) !important; }
.rotate270 { transform: rotate(270deg) !important; }
.rotate315 { transform: rotate(315deg) !important; }
.rotate360 { transform: rotate(360deg) !important; }

.vert-align-wrapper { 
	display: flex; 
	flex-flow: column; 
	justify-content: center; 
	align-items: center; 
	height:100%; 
	width:100%;
}

.opacity-100 { opacity: 1 !important; }
.opacity-75 { opacity: .75 !important; }
.opacity-50 { opacity: .5 !important; }
.opacity-20 { opacity: .25 !important; }
.opacity-0 { opacity: .0 !important; }

.lh-1 { line-height: 1 !important; }
.lh-1-2 { line-height: 1.2 !important; }

.red-color { color: var(--palette-red) !important; }
.orange-color { color: var(--palette-orange) !important; }
.yellow-color { color: var(--palette-yellow) !important; }
.green-color { color: var(--palette-green) !important; }
.sky-color { color: var(--palette-sky) !important; }
.blue-color { color: var(--palette-blue) !important; }

.default-color { color: var(--default-background) !important; }
.action-color { color: var(--action-color-main) !important; }
.info-color { color: var(--info-background) !important; }
.help-color { color: var(--help-background) !important; }
.success-color { color: var(--success-background) !important; }
.warning-color { color: var(--warning-background) !important; }
.error-color { color: var(--error-background) !important; }
.highlight-color { color: var(--highlight-color) !important; }
.faded-color { color: var(--text-color-faded); }

.default-background { background-color: var(--default-background) !important; }
.action-background { background-color: var(--action-color-main) !important; }
.info-background { background-color: var(--info-background) !important; }
.help-background { background-color: var(--help-background) !important; }
.success-background { background-color: var(--success-background) !important; }
.warning-background { background-color: var(--warning-background) !important; }
.error-background { background-color: var(--error-background) !important; }
.highlight-background { background-color: var(--highlight-color) !important; }

.style-default { background-color: var(--default-background) !important; color: var(--default-text) !important; }
.style-action { background-color: var(--action-color-main) !important; color: var(--action-color-contrast-main) !important; }
.style-info { background-color: var(--info-background) !important; color: var(--info-text) !important; }
.style-help { background-color: var(--help-background) !important; color: var(--help-text) !important; }
.style-success { background-color: var(--success-background) !important; color: var(--success-text) !important; }
.style-warning { background-color: var(--warning-background) !important; color: var(--warning-text) !important; }
.style-error { background-color: var(--error-background) !important; color: var(--error-text) !important; }
.style-highlight { background-color: var(--highlight-color) !important; color: var(--highlight-color-contrast) !important; }
.style-highlight-gradient { background: linear-gradient(45deg, var(--highlight-color-gradient) 0%, var(--highlight-color) 100%) !important; color: var(--highlight-color-contrast) !important; }

.hover-as-action:not([disabled]):not(.disabled):hover {
	background-color: var(--action-color-main) !important;
	color: var(--action-color-contrast-main) !important;
}

.center-content {
	display: flex !important;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
}
.center-content-vertical {
	display: flex !important;
	flex-flow: row nowrap;
	align-items: center;
}
.center-content-horizontal {
	display: flex !important;
	flex-flow: row nowrap;
	justify-content: center;
}

.blur {
	filter: blur(var(--blur-overlay));
}
.no-blur {
	filter: blur(0);
}

.cursor-not-allowed { cursor: not-allowed !important; }
.cursor-pointer:not(:disabled):not(.disabled) { cursor: pointer !important; }
.cursor-help { cursor: help !important; }

.mono-font {
	font-family: MainMono, Consolas, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.space-between-horizontal {
	display: flex !important;
	flex-flow: row !important;
	justify-content: space-between !important;
	align-items: center !important;
	gap: 10px;
}
.space-between-vertical {
	display: flex !important;
	flex-flow: column !important;
	justify-content: space-between !important;
	gap: 10px;
}

.spacer {
	flex: 1 !important;
}

.d-block {
	display: block !important;
}

/* Clearfix */
.clearfix:before, .clearfix:after { content: " "; display: table;}
.clearfix:after { clear: both; }
.clearfix { *zoom: 1; }