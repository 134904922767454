#dashboard-page .project-hero {
	--project-hero-size: 30vh;
	--project-hero-min-size: 200px;
	--project-hero-title-size: 2em;
	--project-hero-icon-size: 120px;
	
	height: var(--project-hero-size);
	min-height: var(--project-hero-min-size);
	margin: 
		calc(0px - var(--padding-section) - (10px * 2) - ((0.67em - 10px) * 2) - 2em - 10px) 
		calc(0px - var(--padding-section)) 
		calc((var(--project-hero-icon-size) / 2) + calc(var(--project-hero-icon-size) / 12) + 1em);
	position: relative;
}
html[data-skin-mode='skin-light'] #dashboard-page .project-hero {
	background: url("Skin/images/abstract_bkg.jpg") center no-repeat;
	background-size: cover;
}
html[data-skin-mode='skin-dark'] #dashboard-page .project-hero {
	background: url("Skin/images/abstract_bkg_dark.jpg") center no-repeat;
	background-size: cover;
}
#dashboard-page .project-hero:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	height: max(var(--project-hero-size), var(--project-hero-min-size));
	min-height: 4em;
	width: 100%;
	background-image: linear-gradient(to bottom, transparent 70%, rgba(0, 0, 0, .75) 110%);
}
#dashboard-page .project-hero .project-title {
	display: block;
	max-width: calc(100% - var(--padding-section) - (var(--padding-section) + var(--project-hero-icon-size) + calc(var(--project-hero-icon-size) / 12) + .7em));
	margin: 0;
	font-size: var(--project-hero-title-size);
	line-height: 1;
	font-weight: 600;
	position: absolute;
	left: calc(var(--padding-section) + var(--project-hero-icon-size) + calc(var(--project-hero-icon-size) / 12) + .7em);
	bottom: .6em;
	color: var(--text-color-white);
	text-shadow: #333 1px 1px 2px;
	z-index: 1;
}
#dashboard-page .project-hero .project-icon {
	position: absolute;
	width: var(--project-hero-icon-size);
	height: var(--project-hero-icon-size);
	left: var(--padding-section);
	bottom: calc(0px - (var(--project-hero-icon-size) / 2));
	color: var(--base-color-black);
	background: var(--base-color-white);
	box-shadow: 0 0 0 calc(var(--project-hero-icon-size) / 12) var(--background-color-main-transparent);
	border-radius: 9999em;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
}
#dashboard-page .project-hero .project-icon .icon {
	font-size: calc(var(--project-hero-icon-size) / 1.75);
}

#dashboard-page .quick-campaign-wrapper {
	position: relative;
	overflow: hidden;
}
#dashboard-page .quick-campaign-wrapper:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url("Skin/images/stars.svg") center -20px repeat;
	background-size: 18em;
	opacity: .15;
	pointer-events: none;
}
#dashboard-page .quick-campaign-wrapper:before {
	content: "";
	width: 200px;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	background: url("Skin/images/rocket.svg") -30px -40px no-repeat;
	background-size: 18em;
	opacity: .25;
	pointer-events: none;
}
#dashboard-page .quick-campaign-wrapper > .card-content {
	padding: calc(var(--padding-section) / 1.5) calc(var(--padding-section) / 1.5) calc((var(--padding-section) / 1.5) - 25px);
}
#dashboard-page .quick-campaign-wrapper .page-subtitle {
	margin-top: 0;
	padding-top: 0;
}
#dashboard-page .quick-campaign-wrapper .simple-subtitle-description {
	color: var(--highlight-color-contrast);
	opacity: .5;
}
#dashboard-page .quick-campaign-wrapper .action-card-small {
	z-index: 1;
}
#dashboard-page .quick-campaign-wrapper .action-card-small.soon {
	position: relative;
	opacity: .5;
	cursor: default;
}
#dashboard-page .quick-campaign-wrapper .action-card-small.soon:hover {
	border: 2px solid transparent;
}
#dashboard-page .quick-campaign-wrapper .action-card-small > .card-ribbon {
	position: absolute;
	bottom: 0;
	right: -4px;
	background-color: hsl(var(--palette-triple-right-h), calc(var(--palette-triple-right-s) - 20%), calc(var(--palette-triple-right-l) - 30%));
	color: #fff;
	transform: rotate(-5deg);
	font-size: .875em;
	padding: .25em .5em;
	line-height: 1;
	z-index: 1;
}

#dashboard-page .all-pages-wrapper {
	margin-top: var(--padding-section);
}

@media screen and (max-width: 1280px) {
	#dashboard-page .project-hero {
		--project-hero-title-size: 1.75em;
	}
}
@media screen and (max-width: 1024px) {
	#dashboard-page .project-hero {
		--project-hero-title-size: 1.5em;
		--project-hero-icon-size: 100px;
	}
}
@media screen and (max-width: 768px) {
	#dashboard-page .project-hero {
		--project-hero-min-size: 250px;
		--project-hero-title-size: 1.5em;
		--project-hero-icon-size: 100px;

		margin:
			calc(0px - var(--header-size))
			calc(0px - (var(--padding-section) / 2))
			calc((var(--project-hero-icon-size) / 2) + calc(var(--project-hero-icon-size) / 12) + 1em);
	}
	#dashboard-page .project-hero:after {
		background-image: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, .75) 110%);
	}
	#dashboard-page .project-hero .project-title {
		display: block;
		max-width: none;
		width: 100%;
		text-align: center;
		left: 0;
		bottom: calc((var(--project-hero-icon-size) / 2) + (var(--project-hero-icon-size) / 12) + .5em);
		padding: 0 calc(var(--padding-section) / 2);
	}
	#dashboard-page .project-hero .project-icon {
		left: calc(50% - (var(--project-hero-icon-size) / 2));
	}
	
	#dashboard-page .quick-campaign-wrapper > .card-content {
		padding: calc(var(--padding-section) / 2) calc(var(--padding-section) / 2) calc((var(--padding-section) / 2) - 25px);
	}
}
@media screen and (max-width: 640px) {
	#dashboard-page .quick-campaign-wrapper:before {
		content: "";
		background: url("Skin/images/rocket.svg") -30px 180% no-repeat;
		background-size: 18em;
		opacity: .1;
	}
}