#default-app-home-page .quick-campaign-wrapper {
	position: relative;
	overflow: hidden;
}
#default-app-home-page .quick-campaign-wrapper:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url("Skin/images/stars.svg") center -20px repeat;
	background-size: 18em;
	opacity: .15;
	pointer-events: none;
}
#default-app-home-page .quick-campaign-wrapper:before {
	content: "";
	width: 200px;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	background: url("Skin/images/rocket.svg") -30px -40px no-repeat;
	background-size: 18em;
	opacity: .25;
	pointer-events: none;
}
#default-app-home-page .quick-campaign-wrapper > .card-content {
	padding: calc(var(--padding-section) / 1.5) calc(var(--padding-section) / 1.5) calc((var(--padding-section) / 1.5) - 25px);
}
#default-app-home-page .quick-campaign-wrapper .page-subtitle {
	margin-top: 0;
	padding-top: 0;
}
#default-app-home-page .quick-campaign-wrapper .simple-subtitle-description {
	color: var(--highlight-color-contrast);
	opacity: .5;
}
#default-app-home-page .quick-campaign-wrapper .action-card-small {
	z-index: 1;
}
#default-app-home-page .quick-campaign-wrapper .action-card-small.soon {
	position: relative;
	opacity: .5;
	cursor: default;
}
#default-app-home-page .quick-campaign-wrapper .action-card-small.soon:hover {
	border: 2px solid transparent;
}
#default-app-home-page .quick-campaign-wrapper .action-card-small > .card-ribbon {
	position: absolute;
	bottom: 0;
	right: -4px;
	background-color: hsl(var(--palette-triple-right-h), calc(var(--palette-triple-right-s) - 20%), calc(var(--palette-triple-right-l) - 30%));
	color: #fff;
	transform: rotate(-5deg);
	font-size: .875em;
	padding: .25em .5em;
	line-height: 1;
	z-index: 1;
}

#default-app-home-page .all-projects-wrapper {
	margin-top: var(--padding-section);
}

@media screen and (max-width: 768px) {
	#default-app-home-page .quick-campaign-wrapper > .card-content {
		padding: calc(var(--padding-section) / 2) calc(var(--padding-section) / 2) calc((var(--padding-section) / 2) - 25px);
	}
}

@media screen and (max-width: 640px) {
	#default-app-home-page .quick-campaign-wrapper:before {
		content: "";
		background: url("Skin/images/rocket.svg") -30px 180% no-repeat;
		background-size: 18em;
		opacity: .1;
	}
}