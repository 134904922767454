#notification-source-dialog .dialog-content-component > .title {
	text-align: center;
}
#notification-source-dialog .dialog-content-component > .content {
	padding: 0;
	border-bottom-left-radius: var(--dialog-border-radius);
	border-bottom-right-radius: var(--dialog-border-radius);
	border: 1px solid var(--border-color-main);
	border-top: none;
}

#notification-source-dialog .preview {
	margin: 0 auto;
}