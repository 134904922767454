.message-preview-component {
	--device-width: 240;
	--device-aspect-ratio: 9/19;
	--device-border-radius: 40;
	--device-border: 1px solid #333;
	--device-bezel: 12;
	--device-bezel-color: #000;
	--device-bezel-shadow: inset 0 0 calc(1px * (var(--device-bezel) / 2)) calc(1px * (var(--device-bezel) / 4)) rgba(255, 255, 255, .5);
	--device-screen-border: 1px solid transparent;
	--device-screen-background: #fff;
	--device-screen-font-size: calc(1px * var(--device-width) * 0.037);
	--device-message-background: var(--background-color-secondary);
	--device-message-border: 1px solid var(--background-color-secondary);
	--device-button-background: var(--action-color-main);
	--device-button-color: var(--action-color-contrast-main);

	font-family: Roboto, "open_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: var(--device-screen-font-size);

	border-radius: calc(1px * var(--device-width) * (var(--device-border-radius) / var(--device-width)));
	box-shadow: rgba(0, 0, 0, 0.09) 0 2px 1px, rgba(0, 0, 0, 0.09) 0 4px 2px, rgba(0, 0, 0, 0.09) 0 8px 4px, rgba(0, 0, 0, 0.09) 0 16px 8px, rgba(0, 0, 0, 0.09) 0 32px 16px;
}
html[data-skin-mode='skin-dark'] .message-preview-component {
	--device-screen-background: #111;
	--device-border: 1px solid #282828;
	--device-bezel-color: #000;
	--device-bezel-shadow: inset 0 0 calc(1px * (var(--device-bezel) / 4)) calc(1px * (var(--device-bezel) / 8)) rgba(255, 255, 255, .15);
}

.message-preview-component .preview {
	position: relative;
	width: calc(1px * var(--device-width));
	aspect-ratio: var(--device-aspect-ratio);
	border: var(--device-border);
	border-radius: calc(1px * var(--device-width) * (var(--device-border-radius) / var(--device-width)));
	background-color: var(--device-bezel-color);
	box-shadow: var(--device-bezel-shadow);
}
.message-preview-component .preview > .speaker {
	display: block;
	width: 20%;
	height: calc(2px * var(--device-bezel));
	position: absolute;
	top: calc(1px * var(--device-bezel) * 1.5);
	left: calc(50% - (20% / 2));
	background-color: var(--device-bezel-color);
	z-index: 2;
	border-radius: calc(1px * var(--device-width) * (var(--device-border-radius) / var(--device-width)));
	border: var(--device-screen-border);
	border-top: none;
}
.message-preview-component .preview > .speaker:after {
	content: "";
	display: block;
	position: absolute;
	left: calc(50% - calc((0.6px * var(--device-bezel)) / 2));
	bottom: calc(50% - calc((0.6px * var(--device-bezel)) / 2));
	width: calc(0.6px * var(--device-bezel));
	height: calc(0.6px * var(--device-bezel));
	border-radius: 100%;
	border: var(--device-screen-border);
}

.message-preview-component .screen-wrapper {
	position: absolute;
	top: calc(1px * var(--device-bezel));
	left: calc(1px * var(--device-bezel));
	width: calc(100% - (var(--device-bezel) * 2px));
	height: calc(100% - (var(--device-bezel) * 2px));
	border: var(--device-screen-border);
	border-radius: calc((1px * var(--device-border-radius)) - (1px * var(--device-bezel)));
	z-index: 1;
	background: var(--device-screen-background);
	background-size: cover;
	overflow: hidden;
}
.message-preview-component .screen-wrapper:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	border-radius: calc((1px * var(--device-border-radius)) - (1px * var(--device-bezel)));

	background: linear-gradient(-45deg, transparent, rgba(0, 0, 0, .025) 30%, transparent);
}
.message-preview-component .screen-wrapper > .type-header {
	padding: calc((1px * var(--device-bezel)) + (0.6px * var(--device-bezel)) + 1em) .5em 0 .5em;
	text-align: center;
	font-size: 1.25em;
	font-weight: 600;
}
.message-preview-component .screen {
	line-height: 1.2;
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	column-gap: .5em;
	height: 100%;
	width: 100%;
	padding: calc((1px * var(--device-bezel) * 1.5) + (0.6px * var(--device-bezel)) + 1em) .5em .5em .5em;
	overflow: auto;
	scrollbar-width: thin;
	scrollbar-color: var(--border-color-main) transparent;
}
.message-preview-component .type-header:has(+ .screen) + .screen {
	padding-top: .5em;
}

.message-preview-component .screen .avatar {
	flex: 0 0 1.5em;
	width: 1.5em;
	height: 1.5em;
	background: var(--device-message-background);
	border: var(--device-message-border);
	border-radius: 999rem;
}

.message-preview-component .screen .message {
	flex: 1 1 100%;
	padding: .75em 1em 1em;
	background: var(--device-message-background);
	border: var(--device-message-border);
	border-radius: calc(((1px * var(--device-border-radius)) - (1px * var(--device-bezel))) / 2);
	width: calc(100% - 1.5em - .5em); /* 1.5em is the avatar size and .5em is the padding */
}
.message-preview-component .screen .message > .header > h2,
.message-preview-component .screen .message > .body > .content > h2,
.message-preview-component .screen .message > .body > .content p,
.message-preview-component .screen .actions .button {
	max-width: 100%;
	overflow-x: hidden;
	overflow-wrap: break-word;
	display: block;
}
.message-preview-component .screen .message > .header > h2,
.message-preview-component .screen .message > .body > .content > h2 {
	font-size: 1.5em;
	font-weight: 600;
	margin-top: 0;
	margin-bottom: 0.5em;
}
.message-preview-component .screen .message .body > .content p {
	margin: 0 0 1em 0;
}
.message-preview-component .screen .message > .body > .image > img {
	width: calc(100% + 2em);
	max-width: calc(100% + 2em);
	margin: 0 -1em .5em;
}

.message-preview-component .screen .actions {
	text-align: center;
}
.message-preview-component .screen .actions .button {
	font-size: 1em;
	line-height: 1;
	width: 100%;
	max-width: 400px;
	background: var(--device-button-background);
	color: var(--device-button-color);
	border-radius: 999rem;
	text-align: center;
	padding-top: 0.65em;
	padding-bottom: 0.65em;
}

.message-preview-component .message .time {
	text-align: right;
	font-size: 0.75em;
	margin: 1em 0 0 0;
	opacity: .5;
}