@media screen and (max-width: 479px) {
	#customers-page-title > .actions {
		flex-flow: row-reverse wrap;
		row-gap: 10px;
	}
	#customers-page-title > .actions > .main-button {
		flex: 0 0 100%;
		order: -1;
	}
	#customers-page-title > .actions > .action-button:nth-child(2) {
		flex: 1;
	}
	#customers-page-title > .actions > .action-button:nth-child(2) button {
		width: 100%;
	}
}