#item-page #campaign-general > .form-wrapper  {
	max-width: var(--form-field-max-width);
	margin: 0 auto;
}

@media screen and (max-width: 640px) {
	#message-definition-popup-main-tab .form-field-input.small {
		max-width: 50%;
	}
	#message-definition-popup-main-tab .form-field-input.small > .input-wrapper {
		max-width: 100%;
	}
}
@media screen and (max-width: 480px) {
	#message-definition-popup-main-tab .form-field-input.small {
		max-width: 100%;
	}
}