.wrapper {
	display: inline-block;
}
.wrapper.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.wrapper.readOnly,
.wrapper.readOnly .label {
	cursor: default;
}

.wrapper .label {
	cursor: pointer;

	/* No select */
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.dropdownContent {
	max-width: none;
	min-width: 0;
	position: fixed;
	overflow: auto;
	scrollbar-width: thin;
	transition: left 0.075s ease-out, right 0.075s ease-out, top 0.075s ease-out;
}
.dropdownContent.invisible {
	visibility: hidden;
}
.dropdownContent .loading {
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.dropdownContent .dropdownContentInner {
	display: flex;
	flex-flow: column nowrap;
}
.dropdownContent .dropdownContentInner .item {
	white-space: nowrap;
}