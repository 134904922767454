.wrapper {
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
}

.wrapper .toolbar,
.wrapper .labels,
.wrapper .bottomToolbar {
	flex: 0 0 auto;
}

.wrapper .toolbar {
	
}

.wrapper .labels,
.wrapper .item {
	display: flex;
	flex-flow: row nowrap;
}

.wrapper .labels {
	
}

.wrapper .itemWrapper.maxHeight {
	overflow-y: auto;
}
.wrapper .item {
	flex: 1 1 auto;
}

.wrapper .bottomToolbar {
	
}