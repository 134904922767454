.wrapper {
	
}

.wrapper .logo-link {
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.85;
}
.wrapper .logo-link:hover {
	opacity: 1;
}

.wrapper .logo-link img {
	display: inline-block;
	max-width: 100%;
	padding: var(--sidebar-padding-y) var(--sidebar-padding-x);
}
.wrapper .logo-link.shrank {
	height: auto;
}