html[data-skin-mode='skin-light'] #site-section {
	--hero-image-brightness: 100%;
	--hero-title-color: hsl(var(--palette-triple-right-2-h), calc(var(--palette-triple-right-2-s) + 5%), calc(var(--palette-triple-right-2-l) - 5%));
	--hero-description-color: var(--text-color-main);
	--hero-title-shadow: 1px 1px 0 rgba(255, 255, 255, .5);
}
html[data-skin-mode='skin-dark'] #site-section {
	--hero-image-brightness: 50%;
	--hero-title-color: hsl(var(--palette-triple-right-2-h), calc(var(--palette-triple-right-2-s) + 100%), calc(var(--palette-triple-right-2-l) + 20%));
	--hero-description-color: #fff;
	--hero-title-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
}

#site-section {
	
}

#site-section .hero {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	height: calc(90vh - var(--header-size));
	height: calc(90dvh - var(--header-size));
	min-height: 200px;
	position: relative;
	padding: var(--padding-section);
}
#site-section .hero:before {
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	filter: brightness(var(--hero-image-brightness));
}
#site-section .hero .hero-title,
#site-section .hero .hero-description {
	max-width: 38vw;
	text-align: left;
	color: var(--hero-title-color);
	text-shadow: var(--hero-title-shadow);
}
#site-section .hero .hero-title {
	font-size: 2.25vw;
	font-weight: 900;
	line-height: 1;
	margin: 0;
	letter-spacing: 0.001em;
	word-spacing: 0.15em;
}
#site-section .hero .hero-description {
	font-size: 1.15vw;
	font-weight: 200;
	line-height: 1.15;
	margin: 1em 0 0;
	color: var(--hero-description-color);
}
#site-section .hero .hero-call-to-action {
	font-size: 1vw;
	margin-top: 2rem;
	border-radius: 999rem;
	padding: 0.5em 2em;
}

@media screen and (max-width: 1280px) {
	#site-section .hero .hero-title,
	#site-section .hero .hero-description {
		max-width: 50vw;
	}
	#site-section .hero .hero-title {
		font-size: 3vw;
	}
	#site-section .hero .hero-description {
		font-size: 1.5vw;
	}
	#site-section .hero .hero-call-to-action {
		font-size: 1.25vw;
	}
}
@media screen and (max-width: 1024px) {
	#site-section .hero .hero-title,
	#site-section .hero .hero-description {
		max-width: 60vw;
	}
	#site-section .hero .hero-title {
		font-size: 3vw;
	}
	#site-section .hero .hero-description {
		font-size: 2vw;
	}
	#site-section .hero .hero-call-to-action {
		font-size: 1.5vw;
	}
}

@media screen and (max-width: 640px) {
	#site-home-page .hero {
		overflow: hidden;
		align-items: center;
	}
	#site-home-page .hero:before {
		filter: blur(8px);
		-webkit-filter: blur(8px);
		opacity: .5;
	}
	
	#site-section .hero .hero-title,
	#site-section .hero .hero-description {
		max-width: 100vw;
		text-align: center;
	}
	#site-section .hero .hero-title {
		font-size: 1.75rem;
	}
	#site-section .hero .hero-description {
		font-size: 1.25rem;
	}
	#site-section .hero .hero-call-to-action {
		font-size: 1rem;
		margin-left: auto;
		margin-right: auto;
	}
}