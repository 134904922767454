.key-value-list-component.default-style {
	--key-value-list-labels-height: 40px;
	--key-value-list-select-column-width: 40px;
	--key-value-list-item-spacing: 5px;
	--key-value-list-key-column-width: 40%;
	--key-value-list-key-column-min-width: 50px;
	--key-value-list-value-column-width: 60%;
	--key-value-list-value-column-min-width: 150px;
	
	background: var(--form-control-background);
	border: var(--form-control-border);
	border-radius: var(--input-border-radisu);
	overflow: auto;
}

.key-value-list-component.default-style .select-wrapper,
.key-value-list-component.default-style > .labels > .key-label,
.key-value-list-component.default-style > .labels > .value-label,
.key-value-list-component.default-style > .bottom-toolbar {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
}


/* Define select checkbox */
.key-value-list-component.default-style .select-wrapper {
	flex: 0 0 var(--key-value-list-select-column-width);
}
.key-value-list-component.default-style .select-wrapper .checkbox-component {
	width: 100%;
	flex: 1 1 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	margin: 0;
	padding: 0;
	min-width: var(--key-value-list-select-column-width);
}
.key-value-list-component.default-style .select-wrapper .checkbox-component > .fa {
	text-align: center;
	padding-top: 1px;
}


/* Toolbar ---------------------------------------------------------------------------------------------------------- */
.key-value-list-component.default-style > .toolbar {
	border-width: 0 0 1px;
	border-radius: var(--input-border-radisu) var(--input-border-radisu) 0 0;
}
.key-value-list-component.default-style > .toolbar.max-height {
	position: sticky;
	top: 0;
	z-index: 1;
}
.key-value-list-component.default-style > .toolbar .button-component,
.key-value-list-component.default-style > .toolbar .button {
	min-width: var(--key-value-list-select-column-width);
}


/* Labels ----------------------------------------------------------------------------------------------------------- */
.key-value-list-component.default-style > .labels {
	display: flex;
	flex-flow: row nowrap;
	border-bottom: 1px solid var(--toolbar-border-color);
	background: var(--table-head-background);
	height: var(--key-value-list-labels-height);
}
.key-value-list-component.default-style > .labels:last-of-type {
	border-bottom: none;
	border-bottom-left-radius: var(--input-border-radisu);
	border-bottom-right-radius: var(--input-border-radisu);
}
.key-value-list-component.default-style > .labels.max-height {
	position: sticky;
	top: 37px;
	z-index: 1;
}
.key-value-list-component.default-style > .labels .checkbox-component {
	
}
.key-value-list-component.default-style > .labels > .key-label {
	flex-basis: var(--key-value-list-key-column-width);
	min-width: var(--key-value-list-key-column-min-width);
	margin-right: var(--key-value-list-item-spacing);
}
.key-value-list-component.default-style.not-selectable > .labels > .key-label {
	margin-left: var(--key-value-list-item-spacing);
}
.key-value-list-component.default-style > .labels > .value-label {
	flex-basis: var(--key-value-list-value-column-width);
	min-width: var(--key-value-list-value-column-min-width);
	margin-right: var(--key-value-list-item-spacing);
}
.key-value-list-component.default-style.removable > .labels > .value-label {
	margin-right: 56px;
}


/* Main content ----------------------------------------------------------------------------------------------------- */
.key-value-list-component.default-style.selectable > .form-wrapper {
	padding: 20px 0;
}
.key-value-list-component.default-style.not-selectable > .form-wrapper {
	padding: var(--key-value-list-item-spacing) 0;
}


/* Bottom toolbar --------------------------------------------------------------------------------------------------- */
.key-value-list-component.default-style > .bottom-toolbar {
	height: var(--key-value-list-labels-height);
	border-top: 1px dashed var(--table-border-color);
}
.key-value-list-component.default-style > .bottom-toolbar .button-component {
	margin: 0;
	height: 100%;
}


@media screen and (max-width: 640px) {
	.key-value-list-component.default-style.selectable > .labels > .key-label,
	.key-value-list-component.default-style.selectable > .labels > .value-label,
	.key-value-list-component.default-style:not(.selectable) > .labels {
		display: none;
	}
}