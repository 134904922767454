.capping-plan-status-icon {
	margin-left: 1px;
}
.select-input__option--is-selected > .capping-plan-status-icon {
	margin-left: 1px;
	text-shadow:
		-1px 0 var(--action-color-contrast-main),
		0 1px var(--action-color-contrast-main),
		1px 0 var(--action-color-contrast-main),
		0 -1px var(--action-color-contrast-main);
}
.capping-plan-inactive {
	opacity: .5;
}