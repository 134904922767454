.phone-input-component {
	position: relative;
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	font: var(--font-main-input);
	font-size: inherit;
}

.phone-input-component .form-control {
	flex: 1 1 100%;
	border: var(--form-control-border);
	background-color: var(--form-control-background);
	width: auto;
	min-width: 0;
}

.phone-input-component .flag-dropdown {
	flex: 0 0 auto;
	border: none;
	z-index: 1;
	background: none;
	padding: 1px;
}
.phone-input-component .flag-dropdown.open {
	width: 100%;
	background: none;
	z-index: 3;
}
.phone-input-component .selected-flag,
.phone-input-component .selected-flag:hover, 
.phone-input-component .selected-flag:focus, 
.phone-input-component .selected-flag.open {
	background: var(--form-control-label-background);
	color: var(--form-control-label-text);
}
.phone-input-component .selected-flag {
	border-right: var(--form-control-border);
}
.form-field.error .phone-input-component .selected-flag {
	border-right-color: var(--error-background);
}
.phone-input-component .flag-dropdown.open .selected-flag {
	background: var(--form-control-label-background);
	color: var(--form-control-label-text);
}
.phone-input-component .selected-flag .flag.\30 {
	border: var(--form-control-border);
	background-color: var(--form-control-background);
}

.phone-input-component .country-list {
	width: 100%;
	min-width: 200px;
	margin-left: calc(var(--input-border-radisu) / 2);
	box-shadow: var(--dropdown-shadow);
	border-bottom-left-radius: var(--dropdown-border-radius);
	border-bottom-right-radius: var(--dropdown-border-radius);
	background-color: var(--dropdown-background);
	color: var(--dropdown-text);
	border: var(--dropdown-border);
}
.phone-input-component .country-list .search-box {
	padding: 8px 12px;
}
.phone-input-component .country-list .divider {
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: var(--dropdown-border);
}
.phone-input-component .country-list .country {
	padding: 8px 12px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}
.phone-input-component .country-list .country:hover {
	background-color: var(--dropdown-background-hover);
}
.phone-input-component .country-list .country.highlight {
	background-color: var(--action-color-main);
	color: var(--action-color-contrast-main);
}
.phone-input-component .country-list .country .dial-code {
	color: var(--form-control-placeholder-text);
}
.phone-input-component .country-list .country.highlight .dial-code {
	color: var(--action-color-contrast-main);
	opacity: .75;
}


.phone-input-component.read-only .form-control {
	padding: 0 0 0 25px;
	line-height: inherit;
	height: auto;
	min-height: 0;
	background: none;
	border: none;
	border-radius: 0;
	cursor: text;
	font-size: inherit;
	color: inherit;
}
.phone-input-component.read-only .flag-dropdown .selected-flag {
	background: none;
	border: none;
	border-radius: 0;
	padding-left: 0;
}
.phone-input-component.read-only .flag-dropdown .selected-flag .arrow {
	display: none;
}