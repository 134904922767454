.wrapper {
	
}

.wrapper .pageTitle,
.wrapper .pageDesc {
	line-height: 1.2;
}
.wrapper .pageTitle {
	font-weight: 600;
}
.wrapper .pageDesc {
	font-size: .875em;
	font-weight: 300;
	color: var(--text-color-subtle);
}
.wrapper .pageNotice {
	margin-top: 20px;
	background: none;
	padding: 0;
	border-left: none;
	vertical-align: middle;
}
.wrapper .pageNotice > i {
	font-size: 1.25em;
	float: none;
	margin-right: .35em;
}

.wrapper .pageForm,
.wrapper .channelsForm {
	margin-top: 20px;

	padding: 15px;
	border: var(--form-control-border);
	border-radius: var(--input-border-radisu);
}
.wrapper .channelsForm {
	padding: 25px 15px 15px;
	border: var(--form-control-border);
	border-radius: var(--input-border-radisu);
}

@media screen and (orientation: portrait) {
	.wrapper .pageForm {
		padding-top: 0;
		padding-bottom: 0;
	}
	.wrapper .channelsForm {
		padding-top: 10px;
		padding-bottom: 0;
	}
}