html, html[data-skin-mode='skin-light'] .message-preview-component.message-type-VIBER {
	--viber-preview-border-color: #ececec;
	--viber-preview-background: #fff;
	--viber-preview-message-background: #efefef;
	--viber-preview-button-background: #7360f2;
	--viber-preview-button-color: #fff;
}
html[data-skin-mode='skin-dark'] .message-preview-component.message-type-VIBER {
	--viber-preview-border-color: #3F4C5C;
	--viber-preview-background: #1D2733;
	--viber-preview-message-background: #3F4C5C;
	--viber-preview-button-background: #7B6BDE;
	--viber-preview-button-color: #fff;
}

html .message-preview-component.message-type-VIBER {
	--device-screen-background: var(--viber-preview-background);
	--device-message-background: var(--viber-preview-message-background);
	--device-message-border: 1px solid var(--viber-preview-border-color);
	--device-button-background: var(--viber-preview-button-background);
	--device-button-color: var(--viber-preview-button-color);
}
.message-preview-component.message-type-VIBER .screen .message {
	padding-bottom: .5em;
}
.message-preview-component.message-type-VIBER .screen .avatar {
	border: var(--device-message-border);
}