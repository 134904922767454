.campaign-status-IN_PREPARATION {
	background-color: var(--default-background);
	color: var(--default-text);
}
.campaign-status-ACTIVE {
	background-color: var(--success-background);
	color: var(--success-text);
}
.campaign-status-COMPLETED {
	background-color: var(--background-color-secondary);
	color: var(--text-color-faded);
}
.breadcrumbs .campaign-status-COMPLETED {
	background-color: var(--base-color);
}
.campaign-status-EXPIRED {
	background-color: var(--error-background);
	color: var(--error-text);
}
.breadcrumbs .campaign-status-STOPPED {
	background-color: var(--default-background);
	color: var(--default-text);
}

#main-page-table td > .content .tag {
	margin: 0;
	font-size: 0.875em;
}
#main-page-table td > .content .tag:not(:last-child) {
	margin-right: .5em;
}

#campaign-general.has-overlay-component-overlay,
#message-definitions.has-overlay-component-overlay {
	overflow: unset !important;
	position: sticky !important;
}

#main-page-table .column-customer-messages-count-header > .content {
	justify-content: flex-end;
}

#main-page-table .campaign-customer-message-count-content {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	align-items: flex-start;
	gap: .5em;
}
#main-page-table tbody tr td > .content .campaign-customer-message-count-content > .tag {
	margin: 0;
}

@media screen and (min-width: 1281px) {
	#main-page-table tbody tr td:nth-of-type(3) {
		max-width: 500px;
	}
}

@media screen and (max-width: 1280px) {
	#main-page-table .campaign-customer-message-count-content {
		justify-content: flex-start;
	}
}