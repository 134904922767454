.message-preview-component.message-type-SMS .screen {
	align-items: flex-start;
}
.message-preview-component.message-type-SMS .screen .message {
	position: relative;
	margin-bottom: 1.5em;
	padding-bottom: 0;
}
.message-preview-component.message-type-SMS .message .time {
	position: absolute;
	bottom: -1.5em;
	right: 0;
}

.message-preview-component.message-type-SMS .screen .avatar {
	margin-bottom: 1.5em;
	border: none;
}

.message-preview-component.message-type-SMS .screen .message p {
	margin-bottom: 0;
}