/* Transparent files and preview */
html[data-skin-mode='skin-light'] .file-input-component .files.transparent-background,
html[data-skin-mode='skin-light'] .file-input-component .image-previews.transparent-background {
	background: url("../../../../skin/images/transparent_bkg.png") repeat;
}
html[data-skin-mode='skin-light'] .file-input-component .image-previews.transparent-background .no-img-preview {
	text-shadow: #fff 0 0 8px;
}

html[data-skin-mode='skin-dark'] .file-input-component .files.transparent-background,
html[data-skin-mode='skin-dark'] .file-input-component .image-previews.transparent-background {
	background: url("../../../../skin/images/transparent_bkg_dark.png") repeat;
}
html[data-skin-mode='skin-dark'] .file-input-component .image-previews.transparent-background .no-img-preview {
	text-shadow: #000 0 0 8px;
}