html, html[data-skin-mode='skin-light'] .message-preview-component.message-type-PUSH_NOTIFICATION {
	--push-message-background: rgba(255, 255, 255, 0.85);
}
html[data-skin-mode='skin-dark'] .message-preview-component.message-type-PUSH_NOTIFICATION {
	--push-message-background: rgba(0, 0, 0, 0.75);
}

html .message-preview-component.message-type-PUSH_NOTIFICATION {
	--device-screen-background: url('../../../../skin/images/bkg.jpg') center no-repeat;
	--device-message-background: var(--push-message-background);
}
.message-preview-component.message-type-PUSH_NOTIFICATION .screen {
	backdrop-filter: blur(20px);
}
.message-preview-component.message-type-PUSH_NOTIFICATION .screen .avatar {
	display: none;
}
.message-preview-component.message-type-PUSH_NOTIFICATION .screen .message {
	padding-bottom: 0;
	border-radius: calc((((1px * var(--device-border-radius)) - (1px * var(--device-bezel))) / 2) * 0.5);
}
.message-preview-component.message-type-PUSH_NOTIFICATION .screen .message .app-name {
	font-size: 1em;
	display: flex;
	align-items: center;
	margin-bottom: .5em;
}
.message-preview-component.message-type-PUSH_NOTIFICATION .screen .message .app-name .app-icon {
	height: 1.2em;
	margin-right: .5em;
	margin-top: -.1em;
}
.message-preview-component.message-type-PUSH_NOTIFICATION .message .app-name .time {
	margin: 0 0 0 .5em;
}

.message-preview-component.message-type-PUSH_NOTIFICATION .message > .body {
	display: flex;
	flex-flow: row nowrap;
	gap: 1em;
}

.message-preview-component.message-type-PUSH_NOTIFICATION .message > .body > .image {
	flex: 0 0 30%;
	width: 30%;
}
.message-preview-component.message-type-PUSH_NOTIFICATION .message > .body > .image > img {
	max-width: 100%;
	margin: 0;
	display: block;
}
.message-preview-component.message-type-PUSH_NOTIFICATION .message > .body > .content {
	color: var(--text-color-subtle);
}
.message-preview-component.message-type-PUSH_NOTIFICATION .message > .body > .content > h2 {
	font-size: 1.25em;
	line-height: 1;
	margin-top: .5em;
	margin-bottom: .25em;
	color: var(--text-color-main);
}
.message-preview-component.message-type-PUSH_NOTIFICATION .message > .body > .content > p:last-child {
	margin-bottom: .5em;
}
.message-preview-component.message-type-PUSH_NOTIFICATION .message > .time {
	display: none;
}

.message-preview-component.message-type-PUSH_NOTIFICATION .message > .actions {
	text-align: left;
	color: var(--text-color-subtle);
}
.message-preview-component.message-type-PUSH_NOTIFICATION .message > .actions .button {
	display: inline-block;
	background: none;
	text-align: left;
	width: auto;
	min-width: 0;
	border-radius: 0;
	color: inherit;
	font-weight: bold;
	line-height: 1;
	padding: 0 0 .4em 0;
	margin: 0;
	border: none;
}
.message-preview-component.message-type-PUSH_NOTIFICATION .message > .actions .button:active {
	box-shadow: none;
}