.customer-custom-field-item {
	display: flex;
	flex-flow: row nowrap;
	border-top: none !important;
	border-left: none !important;
}

.customer-custom-field-item .form-control.select-input-component {
	flex-basis: calc(((100% - var(--search-button-size)) * .4) + var(--search-button-size) - 2px);
	min-width: calc(((100% - var(--search-button-size)) * .4) + var(--search-button-size) - 2px);
}

.customer-custom-field-item .form-control-wrapper.text-input-component-wrapper {
	flex-grow: 1;
	min-width: 0;
	border-left: var(--form-control-border);
}
.customer-custom-field-item .select-input-component.standard .select-input__value-container,
.customer-custom-field-item .select-input-component.form-control .select-input__value-container {
	padding-right: 0;
}
.select-input.customer-custom-field-filter-select .select-input__value-container > div:first-of-type,
.select-input.customer-custom-field-filter-select .select-input__placeholder,
.select-input.customer-custom-field-filter-select .select-input__menu-list .select-input__option {
	text-align: right;
}

@media screen and (min-width: 1440px) {
	#root .simple-static-search-component.compact-style:not(.rows-1):not(.rows-2):not(.rows-3):not(.rows-4):not(.rows-5):not(.rows-6):not(.rows-7):not(.rows-8) .customer-custom-field-item .form-control.select-input-component {
		flex: 1 1 calc(35% - 2px);
		min-width: 0;
	}
	#root .simple-static-search-component.compact-style:not(.rows-1):not(.rows-2):not(.rows-3):not(.rows-4):not(.rows-5):not(.rows-6):not(.rows-7):not(.rows-8) .customer-custom-field-item .form-control-wrapper.text-input-component-wrapper {
		flex: 1 1 65%;
		min-width: 0;
	}
}

@media screen and (max-width: 640px) {
	.customer-custom-field-item {
		flex-flow: row wrap;
	}
	.customer-custom-field-item > * {
		flex: 0 0 100% !important;
		min-width: 0 !important;
	}
	.customer-custom-field-item .form-control-wrapper.text-input-component-wrapper {
		border-left: none;
		border-top: var(--form-control-border);
		border-top-style: dashed;
	}
}