#notification-preview-dialog:not(.notification-type-EMAIL) .dialog-content {
	border-radius: 40px;
}

#notification-preview-dialog .dialog-content-component {
	box-shadow: rgba(0, 0, 0, 0.09) 0 2px 1px, rgba(0, 0, 0, 0.09) 0 4px 2px, rgba(0, 0, 0, 0.09) 0 8px 4px, rgba(0, 0, 0, 0.09) 0 16px 8px, rgba(0, 0, 0, 0.09) 0 32px 16px;
	border-radius: 40px;
}
#notification-preview-dialog .dialog-content-component > .title {
	margin-bottom: 0;
}
#notification-preview-dialog .dialog-content-component > .content {
	padding: 0;
}
#notification-preview-dialog .dialog-content-component > .content .message-preview-component {
	box-shadow: none;
}

#notification-preview-dialog .email-preview-header {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	font-size: .75em;
	font-weight: normal;
	color: var(--text-color-subtle);
}
#notification-preview-dialog .email-preview iframe {
	display: block;
	height: 70vh;
	height: 70dvh; /* mobile browsers fix for address bar and system navigation */
	border: none;
	border-bottom-left-radius: var(--dialog-border-radius);
	border-bottom-right-radius: var(--dialog-border-radius);
}