.wrapper {
	height: 100%;
	overflow: visible;
	padding-top: 2px; /* For card top box shadow */
}

.wrapper > .content {
	height: 100%;
	overflow: visible;
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	gap: var(--padding-section);
}
.wrapper > .content > * {
	flex: 0 0 calc(50% - (var(--padding-section) / 2));
}
.wrapper > .content .createItem {
	cursor: pointer;
	border: var(--card-border);
	border-style: dashed;
	box-shadow: none;
}
.wrapper .toolbar {
	
}
.wrapper .movePrevBtn {
	
}
.wrapper .moveNextBtn {
	
}

.wrapper .appliedFilter {
	font-size: 0.75em;
	padding: 15px 15px 0;
}

.wrapper .result {
	padding-left: .25em;
	padding-right: .25em;
}
.wrapper .result .resultSection {
	margin-bottom: 1.5em;
}
.wrapper .result .resultSection:last-of-type {
	margin-bottom: 0;
}
.wrapper .result .resultSection .title {
	font-weight: 600;
	margin: 0;
}
.wrapper .result .resultSection > .content,
.wrapper .result .resultSection > .legend {
	font-size: .75em;
}
.wrapper .result .resultSection > .content {
	width: 100%;
	height: 2em;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	gap: 1em;
}
.wrapper .result .resultSection > .content .barWrapper {
	flex: 1;
	height: 100%;
	overflow: hidden;
}
.wrapper .result .resultSection > .content .bar {
	height: 100%;
	display: flex;
	flex-flow: row nowrap;
	background-color: var(--card-background-fade);
	border-radius: 999em;
	overflow: hidden;
}
.wrapper .result .resultSection > .content .subBar {
	width: 0%;
	height: 100%;
	background-color: var(--transparent-contrast-subtle);
	transition: width 0.15s ease;
}
.wrapper .result .resultSection > .content .barLabel {
	flex: 0 0 auto;
}
.wrapper .result .resultSection > .legend {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	column-gap: 1.5em;
	row-gap: .25em;
	margin-top: .25em;
	line-height: 1;
}
.wrapper .result .resultSection > .legend .legendItem {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	gap: .25em;
}
.wrapper .result .resultSection > .legend .legendItem > span {
	width: 1em;
	height: 1em;
	border-radius: 999em;
}
.wrapper .result .resultSection > .legend .legendItem > label {
	line-height: 1;
	opacity: .5;
}

.wrapper .noResult {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.5em;
	text-align: center;
	line-height: 1;
}
.wrapper .noResult .noResultInner {
	
}
.wrapper .noResult .noResultIcon {
	margin: 0;
	font-size: 4em;
	color: var(--text-color-light);
	display: block;
	overflow: hidden;
}
.wrapper .noResult .noResultIcon:before {
	display: block;
	transform: rotate(90deg);
}
.wrapper .noResult .noResultText {
	margin: .25em 0 0 0;
	color: var(--text-color-faded);
	font-weight: 300;
	line-height: inherit;
}

@media screen and (max-width: 810px) {
	.wrapper > .content {
		flex-flow: column nowrap;
		align-items: flex-start;
	}
	.wrapper > .content > * {
		flex: 0 0 auto;
		max-width: none;
		width: 100%;
	}
	.wrapper > .content .createItem {
		display: none;
	}
	.wrapper #movePrevBtn i:before {
		content: "";
	}
	.wrapper #moveNextBtn i:before {
		content: "";
	}
}