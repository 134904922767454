.wrapper {
	--form-field-max-width: none;
	--padding: calc(var(--padding-section) / 2);
	--element-vertical-space: calc(var(--padding-section) / 2);
	--vertical-space: calc(var(--padding) * 2);
	
	width: 1024px;
	max-width: 90vw;
	max-height: 100vh;
	max-height: 100dvh;
	display: flex;
	flex-flow: column nowrap;
}

.wrapper .logo {
	flex: 0 0 auto;
	text-align: center;
	margin-top: var(--vertical-space);
}
.wrapper .logo > img {
	display: block;
	margin: 0 auto;
	max-width: 100%;
}

.formWrapper {
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
	max-height: 100%;
	min-height: 0;
}
.formWrapper .formTitle,
.formWrapper .formContent,
.formWrapper .formActions {
	box-shadow: var(--card-shadow);
	padding: var(--padding);
}
.formWrapper .formTitle {
	position: relative;
	flex: 0 0 auto;
	font-size: 1.75rem;
	line-height: 1;
	font-weight: 600;
	background-color: var(--highlight-color);
	color: var(--highlight-color-contrast);
	border-top-left-radius: var(--card-border-radius);
	border-top-right-radius: var(--card-border-radius);
}
.wrapper.withLogo .formTitle {
	margin-top: var(--padding);
}
.formWrapper .formTitle.withDesc {
	
}
.formWrapper .formDescription {
	position: relative;
	z-index: 1;
	font-size: 1rem;
	margin-bottom: var(--element-vertical-space);
	color: var(--card-text);
	line-height: 1.15;
}
.formWrapper .formContent {
	flex: 1;
	overflow: auto;
	background: var(--card-background);
	padding-bottom: 0;
}
.formWrapper .formContent .element {
	margin-bottom: var(--element-vertical-space);
}
.formWrapper .formContent .element .elementDescription {
	font-weight: 200;
	margin-top: -.35em;
	margin-bottom: .15em;
	color: var(--text-color-faded);
}
.formWrapper .formActions {
	background-color: var(--background-color-secondary);
	border-top: 1px dashed var(--border-color-main);
	margin-bottom: var(--vertical-space);
	border-bottom-left-radius: var(--card-border-radius);
	border-bottom-right-radius: var(--card-border-radius);
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;
	gap: var(--padding);
}
.formWrapper .formActions > button {
	flex: 0 0 auto;
	white-space: nowrap;
	padding: .65em 1.25em;
	font: var(--font-main);
}
.formWrapper .formActions > button.submitBtn {
	font-weight: 600;
}
.formWrapper .formActions > button.cancelBtn {
	flex: 0 0 auto;
}

.messageWrapper {
	background: var(--card-background);
	box-shadow: var(--card-shadow);
	color: var(--card-text);
	padding: var(--padding-section);
	border-radius: var(--card-border-radius);
}
.wrapper.withLogo .messageWrapper {
	margin-top: var(--padding);
}
.messageWrapper small {
	display: block;
	color: var(--text-color-faded);
}
.messageWrapper .notice {
	text-align: center;
	padding: 0 0 20px;
	line-height: 1;
}
.messageWrapper .notice > i,
.messageWrapper .notice > p {
	margin: 0;
}
.messageWrapper .notice > p {
	margin-bottom: 0.25em;
}
.messageWrapper .notice > p:last-of-type {
	margin-bottom: 0;
}
.messageWrapper .actions {
	margin-top: 1.5rem;
}
.messageWrapper .actions button {
	width: 100%;
	margin-bottom: 1rem;
}
.messageWrapper .actions button:last-of-type {
	margin-bottom: 0;
}
.messageWrapperSpacer {
	flex: 0 0 auto;
	display: block;
	height: var(--vertical-space);
}

@media screen and (max-width: 640px) {
	.wrapper {
		--padding: calc(var(--padding-section) / 3);
		--element-vertical-space: calc(var(--padding-section) / 2);
		--vertical-space: calc(var(--padding) * 2);
	}
	
	.wrapper,
	.messageWrapper {
		width: 100%;
	}

	.formWrapper .formTitle {
		font-size: 1.25em;
	}

	.formWrapper .formActions > button {
		padding: .5em 1em;
	}
}
@media screen and (max-width: 480px) {
	.wrapper .logo > img {
		max-height: 50px;
	}
	
	.formWrapper .formActions > button,
	.formWrapper .formActions > button.cancelBtn {
		flex: 0 0 100%;
	}
}

@media screen and (max-height: 600px) {
	.formWrapper {
		flex: 0 0 auto;
	}
	
	.formWrapper .formContent {
		overflow: visible;
	}
}