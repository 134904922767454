.wrapper {
	min-height: 0 !important;
}

.wrapper .option {
	font-size: 1em;
	color: var(--form-control-text);
	text-transform: none;
	font-weight: 500;
	line-height: 1;
	width: auto;
	height: auto;
	margin: 0;
}

.wrapper .option > span,
.wrapper .option > .icon {
	font-size: 1.25em;
	width: 1em;
	height: 1em;
	line-height: 1;
	margin-right: .25em;
}
.wrapper .option > span {
	display: flex;
	justify-content: center;
	align-items: center;
}
.wrapper .option > span > .icon:nth-child(2) {
	transform: scale(.417);
}
.wrapper .option .icon {
	color: inherit;
}

.wrapper.aligned .option {
	display: inline-flex;
	align-items: center;
	padding: .5em;
	vertical-align: middle;
}
.wrapper.aligned .option.clearBtn .clearIcon {
	font-size: 1.25em;
}

.wrapper.stacked .option {
	display: flex;
	align-items: center;
	padding: .5em .75em;
}
.wrapper.stacked .option.clearBtn .clearIcon {
	font-size: 1.2em;
	margin-right: .35em;
	margin-left: 0.1em;
}