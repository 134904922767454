.wrapper {
	--clear-btn-width: 32px;
	--clear-btn-margin: 5px;

	width: 100%;
}
.wrapper .input {
	cursor: pointer;
}
.wrapper .input[readonly] {
	cursor: default;
}
.wrapper .input:disabled {
	cursor: not-allowed;
}

.wrapper .button {
	margin: 0;
}
.wrapper .clearButton {
	max-height: calc(100% - 2px);
	width: var(--clear-btn-width);
	margin: 0;
}

.wrapper .preview,
.wrapper .files,
.wrapper .imgPreviews {
	width: 100%;
	background: var(--form-control-label-background);
	border: var(--form-control-border);
	border-top: none;
	border-bottom-right-radius: var(--input-border-radisu);
	border-bottom-left-radius: var(--input-border-radisu);
	padding: calc(var(--input-height) / 2 - 14px);
}
.wrapper .file {
	
}
.wrapper .imgPreview {
	padding: calc(var(--input-height) / 2 - 14px);
}
.wrapper .imgPreview img {
	display: block;
	max-width: 100%;
	max-height: 100%;
}
.wrapper .imgPreview > *:hover {
	outline: var(--form-control-border);
	outline-width: 2px;
	outline-style: dashed;
}
.wrapper .imgPreviews.noPreviews {
	display: flex;
	justify-content: center;
	align-items: center;
}
.wrapper .imgPreviews.noPreviews .noImgPreview {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: calc(var(--input-height) / 2 - 14px);
	color: var(--icon-action-color);
}


/* Button only layout */
.wrapper.fullInputWrapper {
	position: relative;
}
.wrapper.fullInputWrapper .input {
	padding-right: calc(var(--input-height) / 2 - 14px + var(--clear-btn-width) + var(--clear-btn-margin));
}
.wrapper.fullInputWrapper.files .input,
.wrapper.fullInputWrapper.hasPreview .input {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.wrapper.fullInputWrapper .clearButton {
	position: absolute;
	top: 1px;
	right: calc(var(--input-height) / 2 - 14px);
	z-index: 1;
	color: var(--icon-action-color);
	font-size: var(--input-font-size);
}
.wrapper.fullInputWrapper .clearButton:focus {
	box-shadow: none;
	color: var(--icon-action-color-hover);
	outline: none;
}


/* Full input field layout */
.buttonOnlyWrapper {
	
}
.buttonOnlyWrapper .inputOverlay {
	display: inline-block;
	position: relative;
}
.buttonOnlyWrapper .input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-height: 0;
	opacity: 0 !important;
	padding: 0;
}
.buttonOnlyWrapper .clearButton {
	margin-left: var(--clear-btn-margin);
}
.buttonOnlyWrapper .files,
.buttonOnlyWrapper .imgPreviews,
.buttonOnlyWrapper .preview {
	border: none;
	border-radius: var(--input-border-radisu);
	margin-top: calc(var(--input-height) / 2 - 14px);
}