#campaign-wizard-dialog {
	--cover-image-height: 150px;
	--icon-size: 100px;
}
#campaign-wizard-dialog.dialog-component .dialog-content-component > .content .form-wrapper {
	--form-label-width: 180px;
}

#campaign-wizard-dialog .dialog-content-component > .title.custom {
	margin: calc(var(--cover-image-height) - (var(--icon-size) / 2)) auto 30px;
}
#campaign-wizard-dialog .dialog-content:before {
	position: absolute;
	content: '';
	z-index: -1;
	width: 100%;
	height: var(--cover-image-height);
	border-top-left-radius: var(--dialog-border-radius);
	border-top-right-radius: var(--dialog-border-radius);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
html[data-skin-mode='skin-light'] #campaign-wizard-dialog .dialog-content:before {
	background-image: url("Skin/images/abstract_bkg.jpg");
}
html[data-skin-mode='skin-dark'] #campaign-wizard-dialog .dialog-content:before {
	background-image: url("Skin/images/abstract_bkg_dark.jpg");
}
#campaign-wizard-dialog .dialog-content-component > .title.custom .icon {
	width: var(--icon-size);
	height: var(--icon-size);
	font-size: calc(var(--icon-size) / 2);
	color: var(--action-color-main);
	box-shadow: 0 0 0 calc(var(--icon-size) * 0.1) var(--card-transparent);
}
#campaign-wizard-dialog .dialog-content-component > .title.custom .title-label {
	margin-top: calc(0px - (var(--icon-size) * 0.2));
	font-weight: 600;
	font-size: calc(var(--icon-size) * 0.24);
	line-height: 1;
	color: var(--action-color-main);
	text-align: center;
	padding: 0 25px;
}

.campaign-wizard-dialog-content .channels-page .channels-form .main-channel,
.campaign-wizard-dialog-content .channels-page .channels-form .fallback-channel {
	margin-bottom: 1em;
}

.campaign-wizard-dialog-content .channels-page .channels-form .channel-settings {
	padding: 0 .5em .5em;
}
.campaign-wizard-dialog-content .channels-page .channels-form .channel-settings .form-field.label-position-stacked {
	margin-bottom: .5em;
}
.campaign-wizard-dialog-content .channels-page .channels-form .channel-settings .form-field:not(:first-of-type).label-position-stacked > .form-field-label {
	padding-top: 0;
}

.campaign-wizard-dialog-content .channels-page .channels-form .channel-actions > .form-field-label  {
	display: none;
}

@media screen and (min-width: 768px) {
	.campaign-wizard-dialog-content .channels-page {
		min-height: 377px;
	}
	.campaign-wizard-dialog-content .channels-page .channels-form {
		min-height: 280px;
		overflow: auto;
	}
}

@media screen and (orientation: portrait) {
	#campaign-wizard-dialog {
		--cover-image-height: 80px;
	}
	#campaign-wizard-dialog .dialog-content-component > .title.custom {
		margin-bottom: 20px;
	}
}