/* Spinners --------------------------------------------------------------------------------------------------------- */
.spinner {
	display: inline-block;
	height: 16px;
	width: 16px;
	background: transparent;
	border-radius: 50%;
	border: 2px solid var(--spinner-color);
	border-top-color: transparent;
	border-bottom-color: transparent;
	animation: spinner1 800ms ease infinite;
}
@keyframes spinner1 {
	to {
		transform: rotate(360deg);
	}
}

/* Overlay component ------------------------------------------------------------------------------------------------ */
.has-overlay-component-position {
	position: relative !important;
}
.has-overlay-component-overlay {
	overflow: hidden !important;
}
.has-overlay-component.has-overlay-component-blur > *:not(.overlay-component) {
	filter: blur(var(--blur-overlay));
}
.has-overlay-component > .overlay-component {
	z-index: var(--z-index-overlay);
}


/* Buttons ---------------------------------------------------------------------------------------------------------- */
.button {
	display: inline-block;
	padding: 0.5em 1em;
	font: var(--button-font);
	font-size: var(--button-font-size);
	font-weight: var(--button-font-weight);
	line-height: 1.2;
	border: 1px solid transparent;
	outline: none;
	transition: var(--transition-hover-out);
	margin: 1px;
	border-radius: var(--button-border-radius);
	cursor: pointer;
	text-decoration: none;
	opacity: 1;
	/* No select */
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.button:disabled {
	opacity: 0.5 !important;
	cursor: not-allowed;
}

.button.icon-only {
	padding: 0.5em 0.6em;
}

.button.big {
	font-size: var(--button-big-font-size);
	font-weight: var(--button-big-font-weight);
}

.button .icon {
	margin-right: 0.5em;
}
.button.icon-only > .icon {
	margin-right: 0;
}

/* Display styles */
.button.default.solid {
	background: var(--button-default-background);
	color: var(--button-default-text);
}
.button.default.solid:hover:not(:disabled) {
	background: var(--button-default-background-hover);
}
.button.default.transparent {
	background: transparent;
	border-color: var(--button-default-background);
	color: var(--button-default-background);
}
.button.default.transparent:hover:not(:disabled) {
	border-color: var(--button-default-background-hover);
	color: var(--button-default-background-hover);
}
.button.default.none {
	color: var(--button-default-background);
}
.button.default.none:hover:not(:disabled) {
	color: var(--button-default-background-hover);
}

.button.highlight.solid {
	background: var(--highlight-color);
	color: var(--highlight-color-contrast);
}
.button.highlight.solid:hover:not(:disabled) {
	background: var(--highlight-color-hover);
}
.button.highlight.transparent {
	background: transparent;
	border-color: var(--highlight-color);
	color: var(--highlight-color);
}
.button.highlight.transparent:hover:not(:disabled) {
	border-color: var(--highlight-color-hover);
	color: var(--highlight-color-hover);
}
.button.highlight.none {
	color: var(--highlight-color);
}
.button.highlight.none:hover:not(:disabled) {
	color: var(--highlight-color-hover);
}

.button.subtle.solid {
	background: var(--button-subtle-background);
	color: var(--button-subtle-solid-text);
}
.button.subtle.solid:hover:not(:disabled) {
	background: var(--button-subtle-background-hover);
	color: var(--button-subtle-solid-text-hover);
}
.button.subtle.transparent {
	background: transparent;
	border-color: var(--button-subtle-background);
	color: var(--button-default-background);
}
.button.subtle.transparent:hover:not(:disabled) {
	border-color: var(--button-subtle-background-hover);
	color: var(--button-default-background-hover);
}
.button.subtle.none {
	color: var(--button-default-background);
}
.button.subtle.none:hover:not(:disabled) {
	color: var(--button-default-background-hover);
}

.button.action.solid {
	background: var(--action-color-main);
	color: var(--action-color-contrast-main);
}
.button.action.solid:hover:not(:disabled) {
	background: var(--action-color-hover);
}
.button.action.transparent {
	background: transparent;
	border-color: var(--action-color-main);
	color: var(--action-color-main);
}
.button.action.transparent:hover:not(:disabled) {
	border-color: var(--action-color-hover);
	color: var(--action-color-hover);
}
.button.action.none {
	color: var(--action-color-main);
}

.button.success.solid {
	background: var(--success-background);
	color: var(--success-text);
}
.button.success.solid:hover:not(:disabled) {
	background: var(--success-background-hover);
}
.button.success.transparent {
	background: transparent;
	border-color: var(--success-background);
	color: var(--success-background);
}
.button.success.transparent:hover:not(:disabled) {
	border-color: var(--success-background-hover);
	color: var(--success-background-hover);
}
.button.success.none {
	color: var(--success-background);
}

.button.warning.solid {
	background: var(--warning-background);
	color: var(--warning-text);
}
.button.warning.solid:hover:not(:disabled) {
	background: var(--warning-background-hover);
}
.button.warning.transparent {
	background: transparent;
	border-color: var(--warning-background);
	color: var(--warning-background);
}
.button.warning.transparent:hover:not(:disabled) {
	border-color: var(--warning-background-hover);
	color: var(--warning-background-hover);
}
.button.warning.none {
	color: var(--warning-background);
}

.button.error.solid {
	background: var(--error-background);
	color: var(--error-text);
}
.button.error.solid:hover:not(:disabled) {
	background: var(--error-background-hover);
}
.button.error.transparent {
	background: transparent;
	border-color: var(--error-background);
	color: var(--error-background);
}
.button.error.transparent:hover:not(:disabled) {
	border-color: var(--error-background-hover);
	color: var(--error-background-hover);
}
.button.error.none {
	color: var(--error-background);
}

/* Display types */
.button.none {
	background: transparent;
	border-color: transparent;
	color: inherit;
}

/* Pseudo */
.button:focus {
	transition: none;
	outline: 1px dotted var(--button-focus-outline-color);
	-moz-outline-radius: var(--button-border-radius);
	box-shadow: inset 0 0 0 1px var(--background-color-main);
}
.button:hover:not(:disabled) {
	transition: var(--transition-hover-in);
	opacity: 1;
}
.button:active:not(:disabled) {
	transition: none;
	border-color: transparent;
	box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.5);
}


/* Dropdown --------------------------------------------------------------------------------------------------------- */
.dropdown-content {
	min-width: var(--dropdown-min-width);
	background: var(--dropdown-background);
	border: var(--dropdown-border);
	-webkit-box-shadow: var(--dropdown-shadow);
	box-shadow: var(--dropdown-shadow);
	border-radius: var(--dropdown-border-radius);
	padding: var(--dropdown-content-padding);
}

.dropdown-component.show .dropdown-content {
	margin-top: var(--dropdown-arrow-size);
}
.dropdown-component .dropdown-content::before {
	position: absolute;
	top: calc(0px - var(--dropdown-arrow-size));
	left: var(--dropdown-arrow-size);
	display: inline-block;
	content: '';
	border-right: var(--dropdown-arrow-size) solid transparent;
	border-bottom: var(--dropdown-arrow-size) solid rgba(var(--dropdown-shadow-rgb), 0.2);
	border-left: var(--dropdown-arrow-size) solid transparent;
}
.dropdown-component .dropdown-content::after {
	position: absolute;
	top: calc(0px - var(--dropdown-arrow-size) + 1px);
	left: calc(var(--dropdown-arrow-size) + 1px);
	display: inline-block;
	content: '';
	border-right: calc(var(--dropdown-arrow-size) - 1px) solid transparent;
	border-bottom: calc(var(--dropdown-arrow-size) - 1px) solid var(--dropdown-background);
	border-left: calc(var(--dropdown-arrow-size) - 1px) solid transparent;
}
.dropdown-component.left .dropdown-content { 
	left: 0; 
	right: auto; 
}
.dropdown-component.left .dropdown-content::before { 
	left: var(--dropdown-arrow-size);
	right: auto;  
}
.dropdown-component.left .dropdown-content::after { 
	left: calc(var(--dropdown-arrow-size) + 1px);
	right: auto;
}
.dropdown-component.right .dropdown-content { 
	left: auto; 
	right: 0; 
}
.dropdown-component.right .dropdown-content::before { 
	left: auto; 
	right: var(--dropdown-arrow-size);
}
.dropdown-component.right .dropdown-content::after { 
	left: auto; 
	right: calc(var(--dropdown-arrow-size) + 1px);
}
.dropdown-component .dropdown-group-name {
	color: var(--dropdown-text);
	text-decoration: none;
	padding: 0.5em 0.75em 0.25em;
	text-align: center;
	font-size: 15px;
	font-weight: 600;
}
.dropdown-component .dropdown-group-name:not(.no-dashes):before {
	content: "--- ";
}
.dropdown-component .dropdown-group-name:not(.no-dashes):after {
	content: " ---";
}
.dropdown-component .dropdown-content .dropdown-content-inner > .separator {
	font-size: 0.75em;
	font-weight: 300;
	color: var(--dropdown-text-faded);
	margin: 1.5em 0 0.5em;
	padding-bottom: 0;
	border-bottom: none;
	text-transform: uppercase;
}
.dropdown-component .dropdown-content .dropdown-content-inner > .separator:first-child {
	margin-top: 0.5em;
}
.dropdown-component > .dropdown-content-inner > .separator {
	font-weight: 600;
	margin: 1em 0 0;
	padding: 0.5em 0.75em;
}
.dropdown-component > .dropdown-content-inner > .separator:first-child {
	margin-top: 0;
}
.dropdown-component .dropdown-content-inner > hr {
	border: var(--dropdown-border);
	border-width: 0 0 1px;
}
.dropdown-component .dropdown-item {
	color: var(--dropdown-text);
	text-decoration: none;
	cursor: pointer;
	padding: 0.5em 0.75em;
	text-align: left;
	font-size: 15px;
	border-radius: var(--dropdown-item-border-radius);
	opacity: 1;
}
.dropdown-component .dropdown-item.active {
	color: var(--action-color-main);
}
.dropdown-component .dropdown-item:hover {
	background-color: var(--dropdown-background-hover);
	opacity: 1;
}
.dropdown-component .dropdown-item.label {
	cursor: default;
}
.dropdown-component .dropdown-item.label:hover {
	background-color: transparent;
}
.dropdown-component .dropdown-content:not(.grid) .dropdown-item > .icon {
	width: 1.5em;
	margin-right: 0 !important;
	margin-left: 0;
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
}
.dropdown-component .dropdown-item.toggle {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	padding-right: 0;
}
.dropdown-component .dropdown-item.toggle .icon {
	flex: 0 0 auto;
	order: 999;
	font-size: 1.5em;
}
.dropdown-component .dropdown-item.toggle .icon.active {
	color: var(--action-color-main);
}
.dropdown-component .dropdown-content.grid {
	padding: var(--dropdown-grid-content-padding);
}
.dropdown-component .dropdown-content.grid .dropdown-content-inner {
	width: var(--dropdown-grid-width);
	display: grid;
	column-gap: 10px;
	row-gap: 10px;
}
.dropdown-component .dropdown-content.grid .dropdown-item {
	text-align: center;
}
.dropdown-component .dropdown-content.grid .dropdown-item .icon {
	display: block;
	font-size: 3em;
	margin-right: 0 !important;
	margin-left: 0 !important;
}
.dropdown-component .dropdown-content.grid .dropdown-item.all {
	grid-column: 1/-1;
	margin: 10px auto;
	color: var(--header-text-color);
}
.dropdown-component .dropdown-content.grid-2 .dropdown-content-inner {
	grid-template-columns: repeat(2, 1fr);
}
.dropdown-component .dropdown-content.grid-3 .dropdown-content-inner {
	grid-template-columns: repeat(3, 1fr);
}
.dropdown-component .dropdown-content.grid-4 .dropdown-content-inner {
	grid-template-columns: repeat(4, 1fr);
}

.dropdown-component.header-dropdown .dropdown-label {
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
}
.dropdown-component.header-dropdown.show .dropdown-label,
.dropdown-component.header-dropdown.opened .dropdown-label {
	height: var(--header-size);
	position: relative;
	z-index: calc(var(--z-index-popup) + 10 + 1);
	background-color: var(--header-bkg-color);
	border-left: var(--dropdown-border);
	border-right: var(--dropdown-border);
	color: var(--header-selected-text-color);
}
.header-right .dropdown-component.header-dropdown.show:last-child .dropdown-label,
.header-right .dropdown-component.header-dropdown.opened:last-child .dropdown-label {
	border-right-color: transparent;
}
.header-left .dropdown-component.header-dropdown.opened:first-child .dropdown-label,
.header-left .dropdown-component.header-dropdown.show:first-child .dropdown-label {
	border-left-color: transparent;
}
.dropdown-component.header-dropdown .dropdown-content {
	position: absolute;
	right: 0;
	margin-top: -1px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	background: var(--header-dropdown-background);
}
.header-right .dropdown-component.header-dropdown:last-child .dropdown-content {
	border-right: none;
}
.header-left .dropdown-component.header-dropdown:first-child .dropdown-content {
	border-left: none;
}
.dropdown-component.header-dropdown .dropdown-content::before,
.dropdown-component.header-dropdown .dropdown-content::after {
	display: none;
}


/** Messages -------------------------------------------------------------------------------------------------------- */
.message-component .message-type-icon { margin-right: 0.5em; }


/** Message dialogs ------------------------------------------------------------------------------------------------- */
body .dialog-component.message-dialog .message-dialog-component .message-content .icon:first-of-type {
	display: none;
}
body .dialog-component.message-dialog .message-dialog-component .message-content {
	margin: 0;
}
body .dialog-component.message-dialog .message-dialog-buttons {
	padding: 25px;
	text-align: center;
}
body .dialog-component.message-dialog .dialog-content-component > .title.custom {
	margin-bottom: 0;
}
body .dialog-component.message-dialog .dialog-content-component > .title.custom .title-label {
	margin-top: 10px;
}


/* Buttons ---------------------------------------------------------------------------------------------------------- */
html[data-skin-mode='skin-dark'] .button-component:not(:disabled), html[data-skin-mode='skin-dark'] .button:not(:disabled) {
	opacity: 0.85;
}
html[data-skin-mode='skin-dark'] .button-component:hover:not(:disabled), html[data-skin-mode='skin-dark'] .button:hover:not(:disabled) {
	opacity: 1;
}


/** Dialogs --------------------------------------------------------------------------------------------------------- */
.dialog-component .dialog-overlay {
	background: rgba(0, 0, 0, 0.6);
}
.dialog-component .dialog-content {
	background: var(--dialog-background);
	border-radius: var(--dialog-border-radius);
	color: var(--dialog-text);
	box-shadow: var(--dialog-box-shadow);
}
.dialog-component .dialog-close {
	background: var(--dialog-background);
	color: var(--dialog-close-text);
}
.dialog-component .dialog-close:hover {
	color: var(--dialog-close-text-hover);
}

/* Dialog content component */
.dialog-component .dialog-content-component {
	display: flex;
	flex-flow: column nowrap;
	min-height: 0;
}
.dialog-component .dialog-content-component > .title,
.dialog-component .dialog-content-component > .content {
	padding-left: 25px;
	padding-right: 25px;
}
.dialog-component .dialog-content-component > .title {
	font-size: 18px;
	font-weight: 600;
	padding-top: 10px;
	margin-bottom: 10px;
}
.dialog-component.bordered-title .dialog-content-component > .title:not(.custom) {
	border-bottom: 1px solid var(--border-color-main);
	padding-bottom: 10px;
	margin-bottom: 0;
	box-shadow: var(--header-box-shadow);
}
.dialog-component .dialog-content-component > .content {
	flex-grow: 1;
	overflow-x: auto;
}
.dialog-component .dialog-content-component > .buttons {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	padding-top: 25px;
}
.dialog-component .dialog-content-component > .buttons button {
	flex-grow: 1;
	border-radius: 0;
	margin: 0;
	font-size: 16px;
	padding: 0.75em 1em;
	border-right: 1px solid rgba(255, 255, 255, 0.2);
	cursor: pointer;
}
.dialog-component .dialog-content-component > .buttons button:first-of-type {
	border-bottom-left-radius: var(--dialog-border-radius);
}
.dialog-component .dialog-content-component > .buttons button:last-of-type {
	border-right: none;
	border-bottom-right-radius: var(--dialog-border-radius);
}
.dialog-component .dialog-content-component > .buttons button:disabled {
	cursor: not-allowed;
}
.dialog-component .dialog-content-component > .buttons button > .icon {
	margin-right: .25em;
}

.dialog-component .dialog-content-component > .title.custom {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	padding: 0;
	margin: -40px auto 20px;
}
.dialog-component .dialog-content-component > .title.custom .icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80px;
	border-radius: 100%;
	background: var(--dialog-background);
	color: var(--dialog-text);
	font-size: 40px;
}
.dialog-component .dialog-content-component > .title.custom .title-label {
	font-size: 26px;
	font-weight: 600;
	margin-top: -10px;
	text-align: center;
	line-height: 1.15;
}
.dialog-component .dialog-content-component > .title.custom.info .icon {
	background: var(--info-background);
	color: var(--info-text);
}
.dialog-component .dialog-content-component > .title.custom.help .icon {
	background: var(--help-background);
	color: var(--help-text);
}
.dialog-component .dialog-content-component > .title.custom.success .icon {
	background: var(--success-background);
	color: var(--success-text);
}
.dialog-component .dialog-content-component > .title.custom.warning .icon {
	background: var(--warning-background);
	color: var(--warning-text);
}
.dialog-component .dialog-content-component > .title.custom.error .icon {
	background: var(--error-background);
	color: var(--error-text);
}

/* Dialogs with forms */
.dialog-component .dialog-content-component > .content .form-wrapper {
	--input-font-size: 1em;
	--input-height: 32px;
	--form-label-width: 160px;

	font-size: 0.875em;
}
.dialog-component .dialog-content-component > .content .form-wrapper .form-field.label-position-none,
.dialog-component .dialog-content-component > .content .form-wrapper .form-field.label-position-stacked {
	margin-bottom: 1rem;
}
.dialog-component .dialog-content-component > .content .form-wrapper .form-field.label-position-stacked > .form-field-label {
	margin-bottom: .25em;
}
.dialog-component .dialog-content-component > .content .form-wrapper .form-field.label-position-aligned {
	margin: 1rem 0;
}

.dialog-component .dialog-content-component > .content .form-wrapper .select-input-component.standard:not(.insert-value-input),
.dialog-component .dialog-content-component > .content .form-wrapper .select-input-component.form-control:not(.insert-value-input) {
	padding: 0;
}


/* Tabs ------------------------------------------------------------------------------------------------------------- */
.tabs {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	padding: 0 15px;
	border-bottom: var(--tab-border);
	margin-bottom: 20px;
}
.tabs.attached {
	padding: 0;
	border-bottom: 0;
	margin-bottom: 0;
}
.tab {
	position: relative;
	padding: 10px 20px;
	background: var(--tab-background);
	border: var(--tab-border);
	border-left: none;
	margin-bottom: -1px;
	color: var(--tab-text);
	cursor: pointer;
	transition: background-color 0.05s ease-out, color 0.05s ease-out;
	overflow: hidden;
	
	-webkit-touch-callout: none; /* no select: iOS Safari */
	-webkit-user-select: none; /* no select: Chrome/Safari/Opera */
	-moz-user-select: none; /* no select: Firefox */
	-ms-user-select: none; /* no select: Internet Explorer/Edge */
	user-select: none; /* no select: Non-prefixed version, currently not supported by any browser */
}
.tab:first-child {
	border-left: var(--tab-border);
}
.tab.active {
	border-top-width: var(--tab-active-top-borde-width);
	border-top-color: var(--tab-active-top-borde-color);
	padding-top: calc(10px - var(--tab-active-top-borde-width) + 1px);
	border-bottom: none;
	background: var(--tab-active-background);
	color: var(--tab-active-text);
}
.tabs.attached .tab.active {
	background: var(--card-background);
	color: var(--card-text);
}
.tab.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}
.tab.invalid {
	border-top-color: var(--error-background);
	color: var(--error-background);
}
.tab:hover:not(.disabled):not(.active):not(.invalid) {
	background: var(--tab-hover-background);
	color: var(--tab-hover-text);
	transition: background-color 0.15s ease-in, color 0.15s ease-in;
}
.tab.invalid:hover {
	color: hsl(var(--error-background-h) var(--error-background-s) calc(var(--error-background-l) - 12%));
}
.tab.status-new:before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: -10px;
	font-size: 10px;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-right-color: var(--success-background);
	transform: rotateZ(-90deg) scaleY(1.2);
}


/* Popups ----------------------------------------------------------------------------------------------------------- */
.popup {
	background: var(--popup-background);
	color: var(--popup-text);
	border-top: var(--popup-border);
	box-shadow: var(--popup-box-shadow);
}

.popups .popup.width-tabs .popup-close {
	background: var(--popup-background-fade);
	opacity: 1;
}

.popup-content {
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
}

.popup-tabs {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	padding: 15px 15px 0;
	background: var(--popup-background-fade);
	border-bottom: var(--popup-border);
}

.popup-tab {
	position: relative;
	padding: 0.875em 1.25em;
	background: var(--popup-background-fade);
	border: var(--popup-border);
	border-left: none;
	margin-bottom: -1px;
	color: var(--popup-tab-text);
	cursor: pointer;
	transition: background-color 0.05s ease-out, color 0.05s ease-out;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	line-height: 1;
}
.popup-tab:first-child {
	border-left: var(--popup-border);
}
.popup-tab.active {
	border-top-width: var(--popup-tab-active-top-borde-width);
	border-top-color: var(--popup-tab-active-top-borde-color);
	padding-top: calc(10px - var(--popup-tab-active-top-borde-width) + 1px);
	border-bottom: none;
	background: var(--popup-background);
	color: var(--popup-tab-text-active);
	box-shadow: 0 1px 0 0 var(--popup-background);
}
.popup-tab.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}
.popup-tab.invalid {
	border-top-color: var(--error-background);
	color: var(--error-background);
}
.popup-tab:hover:not(.disabled):not(.active):not(.invalid) {
	background: var(--popup-tab-hover-background);
	color: var(--popup-tab-hover-text-color);
	transition: background-color 0.15s ease-in, color 0.15s ease-in;
}
.popup-tab.invalid:hover {
	color: hsl(var(--error-background-h) var(--error-background-s) calc(var(--error-background-l) - 12%));
}
.popup-tab.status-new:before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: -10px;
	font-size: 10px;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-right-color: var(--success-background);
	transform: rotateZ(-90deg) scaleY(1.2);
}

.popup-tab-content-loading {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.popup-tab-content {
	flex-grow: 1;
	position: relative;
	overflow: auto;
	padding: var(--popup-content-padding);
	padding-top: 0;
}
.popup-title {
	position: sticky;
	top: 0;
	padding: 20px 0 10px;
	background: var(--popup-background);
	border-bottom: 1px solid var(--border-color-main);
	box-shadow: var(--popup-tab-content-toolbar-border);
	margin: 0 0 20px;
	line-height: 1.2;
	z-index: 4;
}
.popup-tab-content.with-action-buttons .popup-title {
	margin: -92px 0 50px 0;
}
.popup-title small {
	vertical-align: super;
	font-size: 0.5em;
	margin: 0 .5em;
	line-height: 1;
	font-weight: normal;
}
.popup-tab-action-buttons {
	position: sticky;
	top: 0;
	text-align: right;
	padding: 18px 0 10px;
	z-index: 5;
	pointer-events: none;
	touch-action: none;
}
.popup-tab-action-buttons.no-title {
	background: var(--popup-background);
	border-bottom: 1px solid var(--border-color-main);
	box-shadow: var(--popup-tab-content-toolbar-border);
}
.popup-tab-action-buttons .button-component {
	pointer-events: auto;
	touch-action: auto;
	margin-left: 5px;
}
.popup-tab-action-buttons + * {
	min-height: 150px;
}

.popup-global-action-buttons {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	gap: 10px;
	padding: 10px;
	background: var(--popup-background-fade);
	border-top: var(--popup-border);
}
.popup-global-action-buttons .legend,
.popup-global-action-buttons .action-buttons {

}
.popup-global-action-buttons .legend {
	flex: 0 1 auto;
	min-width: 200px;
	padding-left: 10px;
	font-size: 0.875em;
	line-height: 1;
	color: var(--text-color-faded);
}
.popup-global-action-buttons .legend sup strong {
	color: var(--text-color-main);
}
.popup-global-action-buttons .action-buttons {
	flex: 1 0 auto;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	align-items: center;
	gap: 5px;
}
.popup-global-action-buttons .action-buttons .button-component {
	margin: 0;
}
.popup-global-action-buttons .action-buttons > * {
	display: block;
	flex: 0 0 auto;
}
.popup-global-action-buttons .action-buttons > *[data-tooltipped] .button-component {
	width: 100%;
}

@media screen and (max-width: 1280px) {
	.popup-tab {
		padding-left: 0.875em;
		padding-right: 0.875em;
		font-size: 0.875em;
	}

	.popup-global-action-buttons {
		flex-wrap: wrap;
	}
	.popup-global-action-buttons .legend,
	.popup-global-action-buttons .action-buttons {
		flex: 1 1 100%;
		width: 100%;
	}
	.popup-global-action-buttons .legend {
		order: 999;
		text-align: right;
	}
}
@media screen and (max-width: 1024px) {
	.popup-global-action-buttons .action-buttons {
		flex-wrap: wrap;
	}
}
@media screen and (max-width: 640px) {
	.popup-tab {
		padding: 0.75em 0.75em;
	}

	.popup-global-action-buttons .action-buttons {
		flex-direction: row-reverse;
		flex-wrap: wrap-reverse;
		justify-content: space-evenly;
	}
	.popup-global-action-buttons .action-buttons > * {
		flex: 1 1 49%;
		width: 49%;
	}
}
@media screen and (max-width: 360px) {
	.popup-global-action-buttons .action-buttons {
		gap: 5px;
	}
	.popup-global-action-buttons .action-buttons > * {
		flex: 0 0 100%;
		width: 100%;
	}
	.popup-global-action-buttons .action-buttons .action-save_and_close,
	.popup-global-action-buttons .action-buttons .action-close {
		display: none;
	}
}

/* Popup content 'wizard' style */
.popup-content.style-wizard .popup-tabs {
	padding: 0.5em;
	justify-content: center;
	font-size: 1.15em;
}
.popup-content.style-wizard .popup-tab,
.popup-content.style-wizard .popup-tab.active {
	background: none;
	border: none;
	margin: 0;
	padding: 0.875em 1.25em;
	position: relative;
}
.popup-content.style-wizard .popup-tab:not(:last-child):after {
	content: "⇒";
	position: absolute;
	top: calc(50% - 0.5em);
	right: -0.5em;
	z-index: 1;
	color: var(--icon-action-color);
}

@media screen and (max-width: 1280px) {
	.popup-content.style-wizard .popup-tabs {
		font-size: 1em;
	}
}
@media screen and (max-width: 1024px) {
	.popup-content.style-wizard .popup-tabs {
		font-size: 1.25em;
	}
}


/* Sidebar ---------------------------------------------------------------------------------------------------------- */
.sidebar-component {
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	transition: margin-left 0.15s ease-in;
}

.sidebar-component.on-top {
	position: absolute;
	top: 0;
	left: 0;
	z-index: calc(var(--z-index-overlay) + 10);
}

.sidebar-component.hidden {
	margin-left: calc(0px - var(--sidebar-width));
	transition: margin-left 0.15s ease-out;
}

.sidebar-component.on-top .sidebar-overlay {
	opacity: 0;
	transition: var(--transition-hover-out);
}
.sidebar-component.on-top:not(.hidden) .sidebar-overlay {
	position: fixed;
	top: 0;
	left: var(--sidebar-width);
	width: calc(100vw - var(--sidebar-width) + 0px);
	height: 100vh;
	height: 100dvh; /* mobile browsers fix for address bar and system navigation */
	background: var(--background-overlay-transparent);
	opacity: 1;
	transition: var(--transition-hover-in);
}

.sidebar-content {
	flex-grow: 1;
	z-index: 1;
	display: flex;
	flex-flow: column nowrap;
	min-height: 0;
}

.sidebar-content > a {
	flex-basis: auto;
}
.sidebar-content .main-menu {
	flex-grow: 1;
	overflow: auto;
}
body:not(.mobile) .sidebar-component.shrank .sidebar-content .main-menu::-webkit-scrollbar {
	display: none;
}
body:not(.mobile) .sidebar-component.shrank .sidebar-content .main-menu {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}

.sidebar-action-buttons {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}

.sidebar-action-buttons .button-component {
	flex-grow: 1;
	min-width: 100px;
	margin: 0;
	border-right: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 0;
}


/* Cards ------------------------------------------------------------------------------------------------------------ */
.card {
	background: var(--card-background);
	color: var(--card-text);
	border-radius: var(--card-border-radius);
	box-shadow: var(--card-shadow);
	display: flex;
	flex-flow: column nowrap;
	min-height: 0;
}
.card .card-header {
	padding: 10px 15px;
	font-size: 18px;
	font-weight: 600;
	border-bottom: var(--card-border);
}
.card .card-header.with-toolbar {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: stretch;
	padding: 0;
}
.card .card-header.with-toolbar .card-title {
	padding: 10px 15px;
}
.card .card-header.with-toolbar .toolbar {
	display: flex;
	flex-flow: row nowrap;
}
.card .card-header.with-toolbar .toolbar > [data-tooltipped] {
	display: flex !important;
	align-items: stretch;
	justify-content: center;
}
.card .card-header.with-toolbar .toolbar > button,
.card .card-header.with-toolbar .toolbar > .button-component,
.card .card-header.with-toolbar .toolbar > [data-tooltipped] > button,
.card .card-header.with-toolbar .toolbar > [data-tooltipped] > .button-component {
	margin: 0;
	padding: 0 15px;
	border-radius: 0;
}
.card .card-header.with-toolbar .toolbar > button > i,
.card .card-header.with-toolbar .toolbar > .button-component > i,
.card .card-header.with-toolbar .toolbar > [data-tooltipped] > button > i,
.card .card-header.with-toolbar .toolbar > [data-tooltipped] > .button-component > i {
	color: var(--icon-action-color);
}
.card .card-header.with-toolbar .toolbar > button:hover > i,
.card .card-header.with-toolbar .toolbar > .button-component:hover > i,
.card .card-header.with-toolbar .toolbar > [data-tooltipped] > button:hover > i,
.card .card-header.with-toolbar .toolbar > [data-tooltipped] > .button-component:hover > i {
	color: inherit;
}
.card .card-content {
	flex-grow: 1;
	overflow-x: auto;
	padding: 15px;
}
.card .card-actions {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	padding: 10px 0;
	background: var(--card-background-fade);
	border-top: var(--card-border);
	border-bottom-left-radius: inherit;
	border-bottom-right-radius: inherit;
}
.card .card-actions button,
.card .card-actions .button-component {
	margin-right: 10px;
}
.card .card-actions button:last-child,
.card .card-actions .button-component:last-child {
	margin-right: 0;
}

.card.icon-card .card-header {
	text-align: center;
	font-size: 32px;
	font-weight: 100;
	border-bottom: none;
	padding-bottom: 0;
}
.card.icon-card .card-header > .card-header-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 100px;
	border-radius: 100%;
	font-size: 50px;
	background: var(--card-background);
	border-top: var(--card-border);
	color: var(--card-text);
	margin: -60px auto 0 !important;
}
.card.icon-card .card-header > .card-header-icon > img {
	max-width: 50px;
}
.card.icon-card .card-header > .desc {
	font-size: 1rem;
	font-weight: 200;
	line-height: 1.25;
	color: var(--text-color-faded);
}

.card.action-card {
	text-align: center;
	font-size: 1.5rem;
	line-height: 1.2;
	font-weight: 200;
	text-decoration: none;
	opacity: 0.85;
	border: 2px solid transparent;
	cursor: pointer;
	transition: var(--transition-border-out);
	position: relative;
}
.card.action-card.no-action {
	cursor: default;
}
.card.action-card:hover,
.card.action-card:focus-visible,
.card.action-card.no-action {
	opacity: 1;
}
.card.action-card:hover {
	border: 2px solid var(--action-color-main);
	transition: opacity .15s ease-in, var(--transition-border-in);
}
.card.action-card:focus-visible {
	outline: none;
	box-shadow: inset 0 0 0 .2rem var(--transparent-contrast);
}
.card.action-card.active:after {
	content: '';
	display: block;
	height: calc(100% + 4px);
	width: 2px;
	position: absolute;
	top: -2px;
	left: -2px;
	background-color: var(--highlight-color);
	border-left: 2px solid var(--highlight-color);
}
.card.action-card .card-content {
	padding: calc(var(--padding-section) / 1.5);
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}
.card.action-card p {
	margin: 0.5em 0 0 0;
}
.card.action-card .description {
	font-size: 1rem;
	opacity: 0.8;
}
.card.action-card .icon-stack,
.card.action-card .icon:not(.stacked-1):not(.stacked-2)
{
	font-size: 50px;
	width: 1.75em;
	height: 1.75em;
	border-radius: 50%;
	text-align: center;
	line-height: 1.75;
	background: var(--action-color-main);
	color: var(--action-color-contrast-main);
}
.card.action-card .icon.stacked-1 {
	line-height: inherit;
}
.card.action-card.no-action .icon-stack,
.card.action-card.no-action .icon:not(.stacked-1):not(.stacked-2)
{
	background: var(--text-color-faded);
}
.card.action-card.colored {
	border: none;
	background-image: linear-gradient(to right top, #1e4b9d, #6447a5, #973ba0, #c32891, #e51277);
	color: var(--action-color-contrast-main);
}
.card-grid.rainbow .card.action-card:nth-child(5n+1),
.card.action-card.colored.color-1 {
	background-image: linear-gradient(to right top, #8d68fd, #4b8bff, #00a5ff, #00b8fe, #56c8ee);
}
.card-grid.rainbow .card.action-card:nth-child(5n+2),
.card.action-card.colored.color-2 {
	background-image: linear-gradient(to right top, #a257fe, #b55af5, #c45eec, #d064e4, #da6bdd);
}
.card-grid.rainbow .card.action-card:nth-child(5n+3),
.card.action-card.colored.color-3 {
	background-image: linear-gradient(to right top, #21aa93, #16b895, #17c595, #25d393, #39e08e);
}
.card-grid.rainbow .card.action-card:nth-child(5n+4),
.card.action-card.colored.color-4 {
	background-image: linear-gradient(to right top, #fcab34, #ff9852, #ff8a6f, #ff818c, #fa7fa5);
}
.card-grid.rainbow .card.action-card:nth-child(5n+0),
.card.action-card.colored.color-5 {
	background-image: linear-gradient(to right top, #990c3e, #b81260, #d22288, #e739b7, #f256eb);
}
.card.action-card.colored .icon-stack,
.card.action-card.colored .icon:not(.stacked-1):not(.stacked-2)
{
	background: rgba(0, 0, 0, 0.25);
	color: var(--action-color-contrast-main);
}

.card.action-card-small {
	text-align: left;
	font-size: 1rem;
	line-height: 1.2;
	text-decoration: none;
	cursor: pointer;
	color: var(--card-text);
	border: 2px solid transparent;
	background-image: var(--card-background);
	opacity: .85;
	transition: opacity .15s ease-out;
}
.card.action-card-small:hover,
.card.action-card-small.no-action {
	opacity: 1;
	border: 2px solid var(--action-color-main);
	transition: opacity .15s ease-in, var(--transition-border-in);
}
.card.action-card-small.no-action {
	cursor: default;
	border-color: transparent;
}
.card.action-card-small[disabled] {
	opacity: .5;
	filter: grayscale(1.0);
	cursor: not-allowed;
}
.card.action-card-small .card-content {
	padding: .5rem;
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}
.card.action-card-small .card-content .shortcut {
	position: absolute;
	top: 4px;
	right: 4px;
	opacity: 0.5;
	font: var(--font-main-mono);
	font-size: 10px;
	line-height: 1;
}
.card.action-card-small .icon {
	flex: 0 0 auto;
	font-size: 1.2rem;
	margin-right: 1rem;
	width: 1.75em;
	height: 1.75em;
	border-radius: 50%;
	text-align: center;
	line-height: 1.75;
	background: var(--action-color-main);
	color: var(--action-color-contrast-main);
}
.card.action-card-small p {
	display: inline-block;
	font-size: 1.15rem;
	line-height: 1;
	font-weight: 600;
	margin: 0 0 .35em 0;
}
.card.action-card-small .description {
	font-size: .8rem;
	line-height: 1;
	opacity: 0.75;
}
.card.action-card-small.info {
	color: var(--info-text);
	background-image: var(--info-background);
}
.card.action-card-small.help {
	color: var(--help-text);
	background-image: var(--help-background);
}
.card.action-card-small.success {
	color: var(--success-text);
	background-image: var(--success-background);
}
.card.action-card-small.warning {
	color: var(--warning-text);
	background-image: var(--warning-background);
}
.card.action-card-small.error {
	color: var(--error-text);
	background-image: var(--error-background);
}

.card.info-card { 
	font-size: 1rem;
	background: var(--card-background-fade);
	box-shadow: 0 3px 0 0 rgba(var(--card-shadow-rgb), 0.1);
	border-color: rgba(var(--card-shadow-rgb), 0.1);
	position: relative;
}
.card.info-card .card-content {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: flex-start;
}
.card.info-card .info-value { 
	font-size: 1.5em;
	line-height: 1;
	font-weight: 800;
	margin: 0.25em 0 0 0;
	color: var(--text-color-main);
}
.card.info-card .info-label {
	font-weight: 300;
	line-height: 1.15;
	margin: 0 0 .15em;
	color: var(--text-color-faded);
}
.card.info-card .card-actions {
	position: absolute;
	bottom: 0;
	width: 100%;
	background: none;
	justify-content: flex-end;
	padding: 0 5px;
	border: none;
}
.card.info-card .info-help {
	font-size: .75em;
	line-height: 1.1;
	color: var(--text-color-faded);
	padding: 1em 15px 15px;
	margin: 1em -15px -15px;
	border-top: 1px solid rgba(var(--card-shadow-rgb), 0.1);
	box-shadow: inset 0 0 0 100px rgba(var(--card-shadow-rgb), 0.05);
}

.card.statistic-card {
	text-align: left;
	font-size: 1rem;
	line-height: 1.2;
	color: var(--card-text);
	border: 2px solid transparent;
	background-image: var(--card-background);
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}
a.card.statistic-card {
	text-decoration: none;
	cursor: pointer;
	opacity: .85;
	transition: opacity .15s ease-out;
}
.card.statistic-card.disabled {
	opacity: .5;
	filter: grayscale(1.0);
	cursor: not-allowed;
}
a.card.statistic-card:not(.disabled):hover {
	opacity: 1;
	border: 2px solid var(--action-color-main);
	transition: opacity .15s ease-in, var(--transition-border-in);
}
.card.statistic-card .card-icon {
	order: 9999;
	flex: 0 0 auto;
	font-size: 2rem;
	padding: 1rem;
	text-align: center;
	color: var(--icon-action-color);
	opacity: .35;
}
a.card.statistic-card:not(.disabled):hover .card-icon {
	color: var(--action-color-main);
	opacity: 1;
	transition: color .15s ease-in, opacity .15s ease-in;
}
.card.statistic-card .card-content {
	padding: 1rem;
}
.card.statistic-card .card-content .value {
	display: inline-block;
	font-size: 1.75rem;
	line-height: 1;
	font-weight: 700;
	color: var(--highlight-color);
}
.card.statistic-card .card-content .description {
	font-size: 1rem;
	line-height: 1;
	opacity: .75;
	font-weight: 600;
	margin-bottom: .25em;
}

.card-grid.xs {
	--card-grid-margin: 15px;
}
.card.statistic-card.xs {
	font-size: .75rem;
}
.card.statistic-card.xs .card-icon {
	font-size: 1.5rem;
	padding: .75rem;
}
.card.statistic-card.xs .card-content {
	padding: .75rem;
}
.card.statistic-card.xs .card-content .value {
	font-size: 1rem;
}
.card.statistic-card.xs .card-content .description {
	font-size: .75rem;
}


/* Card grid */
.card-grid {
	--card-grid-margin: 25px;
	
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	min-width: 0;
	width: 100%;
}
.card-grid .card {
	max-width: 100%;
	flex: 1 1 100%;
	margin: 0 25px 25px 0;
}

@media screen and (max-width: 639px) {
	.card-grid .card {
		margin: 0 var(--card-grid-margin) var(--card-grid-margin) 0;
		margin-right: 0;
	}
}
@media screen and (min-width: 640px) {
	.card-grid .card {
		max-width: calc(50% - var(--card-grid-margin) + var(--card-grid-margin)/2);
		flex: 1 1 calc(50% - var(--card-grid-margin) + var(--card-grid-margin)/2);
		margin: 0 var(--card-grid-margin) var(--card-grid-margin) 0;
	}
	.card-grid .card:nth-child(4n+0),
	.card-grid .card:nth-child(3n+0) { margin-right: var(--card-grid-margin); }
	.card-grid .card:nth-child(2n+0) { margin-right: 0; }
}
@media screen and (min-width: 769px) {
	.card-grid .card {
		max-width: calc(33.3333% - var(--card-grid-margin) + var(--card-grid-margin)/3);
		flex: 1 1 calc(33.3333% - var(--card-grid-margin) + var(--card-grid-margin)/3);
		margin: 0 var(--card-grid-margin) var(--card-grid-margin) 0;
	}
	.card-grid .card:nth-child(2n+0),
	.card-grid .card:nth-child(4n+0) { margin-right: var(--card-grid-margin); }
	.card-grid .card:nth-child(3n+0) { margin-right: 0; }

	.card-grid.per-row-2 .card {
		flex-basis: calc(50% - var(--card-grid-margin) + var(--card-grid-margin)/3);
		max-width: calc(50% - var(--card-grid-margin) + var(--card-grid-margin)/3);
	}
	.card-grid.per-row-2 .card:nth-child(3n+0),
	.card-grid.per-row-2 .card:nth-child(4n+0) { margin-right: var(--card-grid-margin); }
	.card-grid.per-row-2 .card:nth-child(2n+0) { margin-right: 0; }
}
@media screen and (min-width: 1025px) {
	.card-grid .card {
		max-width: calc(50% - var(--card-grid-margin) + var(--card-grid-margin)/2);
		flex: 1 1 calc(50% - var(--card-grid-margin) + var(--card-grid-margin)/2);
		margin: 0 var(--card-grid-margin) var(--card-grid-margin) 0;
	}
	.card-grid .card:nth-child(4n+0),
	.card-grid .card:nth-child(3n+0) { margin-right: var(--card-grid-margin); }
	.card-grid .card:nth-child(2n+0) { margin-right: 0; }
	.card-grid.per-row-2 .card {
		flex-basis: calc(50% - var(--card-grid-margin) + var(--card-grid-margin)/2);
		max-width: calc(50% - var(--card-grid-margin) + var(--card-grid-margin)/2);
	}
}
@media screen and (min-width: 1280px) {
	.card-grid .card,
	.card-grid.per-row-4 .card,
	.card-grid.per-row-5 .card,
	.card-grid.per-row-6 .card,
	.card-grid.per-row-8 .card {
		max-width: calc(33.3333% - var(--card-grid-margin) + var(--card-grid-margin)/3);
		flex: 1 1 calc(33.3333% - var(--card-grid-margin) + var(--card-grid-margin)/3);
		margin: 0 var(--card-grid-margin) var(--card-grid-margin) 0;
	}
	.card-grid.per-row-4 .card:nth-child(2n+0),
	.card-grid.per-row-4 .card:nth-child(4n+0),
	.card-grid.per-row-5 .card:nth-child(2n+0),
	.card-grid.per-row-5 .card:nth-child(4n+0),
	.card-grid.per-row-6 .card:nth-child(2n+0),
	.card-grid.per-row-6 .card:nth-child(4n+0),
	.card-grid.per-row-8 .card:nth-child(2n+0),
	.card-grid.per-row-8 .card:nth-child(4n+0) { margin-right: var(--card-grid-margin); }
	.card-grid.per-row-4 .card:nth-child(3n+0),
	.card-grid.per-row-5 .card:nth-child(3n+0),
	.card-grid.per-row-6 .card:nth-child(3n+0),
	.card-grid.per-row-8 .card:nth-child(3n+0) { margin-right: 0; }

	.card-grid.per-row-3 .card {
		flex-basis: calc(33.3333% - var(--card-grid-margin) + var(--card-grid-margin)/3);
		max-width: calc(33.3333% - var(--card-grid-margin) + var(--card-grid-margin)/3);
	}
	.card-grid.per-row-3 .card:nth-child(2n+0),
	.card-grid.per-row-3 .card:nth-child(4n+0) { margin-right: var(--card-grid-margin); }
	.card-grid.per-row-3 .card:nth-child(3n+0) { margin-right: 0; }
}
@media screen and (min-width: 1440px) {
	.card-grid .card,
	.card-grid.per-row-5 .card {
		max-width: calc(20% - var(--card-grid-margin) + var(--card-grid-margin)/5);
		flex: 1 1 calc(20% - var(--card-grid-margin) + var(--card-grid-margin)/5);
		margin: 0 var(--card-grid-margin) var(--card-grid-margin) 0;
	}
	.card-grid.per-row-5 .card:nth-child(2n+0),
	.card-grid.per-row-5 .card:nth-child(3n+0) { margin-right: var(--card-grid-margin); }
	.card-grid.per-row-5 .card:nth-child(5n+0) { margin-right: 0; }
	
	.card-grid.per-row-4 .card {
		max-width: calc(25% - var(--card-grid-margin) + var(--card-grid-margin)/4);
		flex: 1 1 calc(25% - var(--card-grid-margin) + var(--card-grid-margin)/4);
		margin: 0 var(--card-grid-margin) var(--card-grid-margin) 0;
	}
	.card-grid.per-row-4 .card:nth-child(2n+0),
	.card-grid.per-row-4 .card:nth-child(3n+0) { margin-right: var(--card-grid-margin); }
	.card-grid.per-row-4 .card:nth-child(4n+0) { margin-right: 0; }

	.card-grid.per-row-6 .card {
		max-width: calc(16.6667% - var(--card-grid-margin) + var(--card-grid-margin)/6);
		flex: 1 1 calc(16.6667% - var(--card-grid-margin) + var(--card-grid-margin)/6);
	}
	.card-grid.per-row-6 .card:nth-child(2n+0),
	.card-grid.per-row-6 .card:nth-child(3n+0) { margin-right: var(--card-grid-margin); }
	.card-grid.per-row-6 .card:nth-child(6n+0) { margin-right: 0; }

	.card-grid.per-row-8 .card {
		max-width: calc(12.5% - var(--card-grid-margin) + var(--card-grid-margin)/8);
		flex: 1 1 calc(12.5% - var(--card-grid-margin) + var(--card-grid-margin)/8);
	}
	.card-grid.per-row-8 .card:nth-child(2n+0),
	.card-grid.per-row-8 .card:nth-child(3n+0) { margin-right: var(--card-grid-margin); }
	.card-grid.per-row-8 .card:nth-child(8n+0) { margin-right: 0; }

	.card-grid.per-row-3 .card:nth-child(2n+0),
	.card-grid.per-row-3 .card:nth-child(4n+0) { margin-right: var(--card-grid-margin); }
	.card-grid.per-row-3 .card:nth-child(3n+0) { margin-right: 0; }
}


/* Labels (Label, NumberLabel, DateLabel, ...) ---------------------------------------------------------------------- */
.label-prefix { margin-right: 5px }
.label-suffix { margin-left: 5px }


/* Toolbar */
.toolbar {
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
}
.toolbar.left {
	justify-content: flex-start;
}
.toolbar.middle {
	justify-content: center;
}
.toolbar.right {
	justify-content: flex-end;
}
.toolbar > * {
	flex: 0 0 auto;
}
.toolbar > .toolbar-title {
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	font-size: 1.2em;
	padding: .25em .75em;
}
.toolbar.standard {
	background: var(--toolbar-backround);
	border: 1px solid var(--toolbar-border-color);
	border-radius: 4px;
}
.toolbar.standard .button-component,
.toolbar.standard .button {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	flex: 0 0 auto;
	border-radius: 0;
	margin: 0;
	min-width: 36px;
	height: 36px;
	padding-top: 0;
	padding-bottom: 0;
	color: var(--toolbar-text-color);
}
.toolbar.standard .button-component:first-child,
.toolbar.standard .button:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.toolbar.standard .button-component:last-child,
.toolbar.standard .button:last-child {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.toolbar.standard .button-component.transparent,
.toolbar.standard .button-component.none,
.toolbar.standard .button.transparent,
.toolbar.standard .button.none
{
	border: none;
}
.toolbar.standard .button-component.transparent:hover,
.toolbar.standard .button-component.none:hover,
.toolbar.standard .button.transparent:hover,
.toolbar.standard .button.none:hover
{
	box-shadow: inset 0 0 0 999em var(--toolbar-backround-hover);
}
.toolbar.standard .button-component .icon,
.toolbar.standard .button .icon
{
	font-size: 16px;
}
.toolbar.standard .button-component.with-tooltip,
.toolbar.standard .button.with-tooltip {
	padding: 0;
}
.toolbar.standard .button-component.with-tooltip [data-tooltipped],
.toolbar.standard .button.with-tooltip [data-tooltipped] {
	padding: 0 1em;
	height: 100%;
	display: flex !important;
	align-items: center;
	line-height: 1;
}
.toolbar.standard .separator {
	flex: 0 0 auto;
	width: 1px;
	border-right: 1px solid var(--toolbar-separator-border-color);
	box-shadow: 1px 0 0 0 var(--toolbar-separator-outline-color);
}
.toolbar.standard .label {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	flex: 0 0 auto;
	padding: 0 10px;
}
.toolbar.standard .form-control {
	border-top: none;
	border-bottom: none;
	border-right: none;
}
.toolbar.standard > *:first-child .form-control {
	border-top-left-radius: 4px;
	border-left-width: 0;
}

.toolbar.standard.for-table {
	border-bottom: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-color: var(--table-border-color);
}
.toolbar.standard.for-table-with-filter {
	border: 1px solid var(--table-border-color);
	border-top: none;
	border-radius: 0;
}
.toolbar.standard.for-table-with-filter .button,
.toolbar.standard.for-table-with-filter .button-component {
	border-radius: 0;
}

.toolbar.standard.big {

}
.toolbar.standard.big .button-component,
.toolbar.standard.big .button {
	box-sizing: content-box;
	height: auto;
	padding: .8em 1em;
}


/* Pagination ------------------------------------------------------------------------------------------------------- */
.pagination-label {
	font-size: 0.75em;
}
.pagination-label .label-prefix {
	margin: 5px;
}


/* Data table ------------------------------------------------------------------------------------------------------- */
.data-table-component.standard {
	display: block;
	overflow: auto;
	font-size: var(--table-font-size);
	border: 1px solid var(--table-border-color);
}
.data-table-component.standard table {
	width: auto;
	min-width: 100%;
	border-spacing: 0;
	-webkit-border-horizontal-spacing: 0;
	-webkit-border-vertical-spacing: 0;
}
.data-table-component.standard table caption {
	font-size: 1.25em;
	font-weight: bold;
	padding: 0.35em 1em;
	background: var(--background-color-secondary);
	border-bottom: 1px solid var(--table-border-color);
}
.data-table-component.standard th,
.data-table-component.standard td {
	border-right-width: 1px;
	border-right-style: none;
}
.data-table-component.standard th:last-of-type,
.data-table-component.standard td:last-of-type {
	border-right: none;
}
.data-table-component.standard.no-last-column-border th:nth-last-child(2),
.data-table-component.standard.no-last-column-border td:nth-last-child(2) {
	border-right: none;
}
.data-table-component.standard.has-vert-scrollbar .data-table {
	border-right-width: 1px;
	border-right-style: solid;
}
.data-table-component.standard.has-vert-scrollbar .data-table,
.data-table-component.standard th,
.data-table-component.standard td {
	border-right-color: var(--table-border-color);
}

.data-table-component.standard thead th {
	background: var(--table-head-background);
	color: var(--table-head-text-color);
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: var(--table-border-color);
	text-align: left;
}
.data-table-component.standard thead tr.clear-selection th,
.data-table-component.standard thead tr.top-row th {
	background: var(--table-head-background);
	border-bottom-width: 1px;
	padding-top: 0;
	padding-bottom: 0;
}
.data-table-component.standard thead .content {
	display: flex;
	align-items: center;
	min-height: 2.714em;
	padding: 0.35em 1em;
	line-height: 1.2;
}
.data-table-component.standard thead tr.clear-selection .content {
	justify-content: center;
	color: var(--action-color-main);
}
.data-table-component.standard thead th.sortable .label-prefix {
	margin-right: 0;
}
.data-table-component.standard thead th.sortable:hover {
	box-shadow: var(--hover-box-shadow);
	transition: var(--transition-hover-in);
}
.data-table-component.standard thead th.sortable .content {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	min-height: unset;
	padding-left: 0.5em;

	/* No select */
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently not supported by any browser */
}
.data-table-component.standard thead th.sortable.sort .content,
.data-table-component.standard thead th.sortable.sort .content .sort-icon-active {
	color: var(--table-head-sorted-text-color);
}

.data-table-component.standard tbody {
	background: var(--table-background);
}
.data-table-component.standard tbody tr:nth-child(even) {
	background: var(--table-row-even-background);
}
.data-table-component.standard tbody tr:nth-child(odd) {
	background: var(--table-row-odd-background);
}
.data-table-component.standard tbody td {
	border-bottom-color: var(--table-inner-border-color);
	color: var(--table-row-text-color);
	margin: 0;
	padding: 0;
	border-bottom-width: 1px;
	border-bottom-style: solid;
}
.data-table-component table.with-pagination tbody tr:last-of-type td {
	border-bottom: none;
}
.data-table-component.standard tbody td.colspan-full {
	background: var(--table-head-background);
	font-weight: bold;
	text-align: center;
}

.data-table-component table .select-checkbox,
.data-table-component table tbody .content,
.data-table-component table tbody .actions .action-btn,
.data-table-component table tbody .action .action-btn {
	padding: 0.6em 1em;
}
.data-table-component table tbody .actions .action-btn.big,
.data-table-component table tbody .action .action-btn.big {
	padding: 0.4em 0.5em;
}
.data-table-component table tbody .actions .action-btn:not(.default):not(.subtle):not(.error):not(.warning):not(.success),
.data-table-component table tbody .action .action-btn:not(.default):not(.subtle):not(.error):not(.warning):not(.success) {
	color: var(--action-color-main);
	box-shadow: var(--hover-clear-box-shadow);
	transition: var(--transition-hover-out);
}
.data-table-component table tbody .actions .action-btn:hover,
.data-table-component table tbody .action .action-btn:hover
{
	box-shadow: var(--hover-box-shadow) !important;
	transition: var(--transition-hover-in) !important;
}
.data-table-component table thead .select .content,
.data-table-component table tbody .select .content,
.data-table-component table tbody .actions .content,
.data-table-component table tbody .action .content {
	padding: 0;
}
.data-table-component:not(.mobile-data-table) table tbody .actions {
	white-space: nowrap;
	text-align: right;
}
.data-table-component.mobile-data-table table tbody .standard-actions-row {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	gap: .5em;
	padding: .5em;
}
.data-table-component.mobile-data-table table tbody .standard-actions-row > div {
	flex: 1;
}
.data-table-component.mobile-data-table table tbody tr td .standard-actions-row > div > .button-component,
.data-table-component.mobile-data-table table tbody tr td .standard-actions-row > div > button,
.data-table-component.mobile-data-table table tbody tr td .standard-actions-row > div > a {
	width: 100%;
	background-color: var(--table-row-highlight-background);
	margin: 0;
}

.data-table-component.standard .data-table-pagination {
	background: var(--table-head-background);
	color: var(--table-head-text-color);
	border-top-color: var(--table-border-color);
	border-top-width: 1px;
	border-top-style: solid;
	padding: 0.35em 0;
}
.data-table-component.standard.has-vert-scrollbar .data-table-pagination {
	border-right-width: 1px;
	border-right-style: solid;
	border-right-color: var(--table-border-color);
}
.data-table-component.standard.has-hor-scrollbar .data-table-pagination {
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: var(--table-border-color);
}

.data-table-component.standard .highlighted-hover tr td,
.data-table-component.standard .highlighted-hover tr:hover.noData td {
	box-shadow: inset 0 0 0 999em transparent;
	transition: var(--transition-hover-out);
}
.data-table-component.standard .highlighted-hover tr:hover td {
	box-shadow: inset 0 0 0 999em var(--table-row-highlight-background);
}
.data-table-component.standard .highlighted {
	background: var(--action-color-main);
	color: var(--action-color-contrast-main);
}
.data-table-component.standard .highlighted .action .action-btn,
.data-table-component.standard .highlighted .actions .action-btn {
	color: var(--action-color-contrast-main) !important;
}

.data-table-component.standard.sticky-last-column:not(.mobile-data-table) thead tr:not(.clear-selection):not(.top-row):not(.no-data) th:last-child,
.data-table-component.standard.sticky-last-column:not(.mobile-data-table) tbody tr:not(.clear-selection):not(.top-row):not(.no-data) td:last-child {
	position: sticky;
	right: 0;
	border-left: 1px solid var(--table-border-color);
}
.data-table-component.standard.sticky-last-column:not(.mobile-data-table) tbody tr:nth-child(2n+1) td:last-child {
	background:
		linear-gradient(var(--table-row-odd-background), var(--table-row-odd-background)),
		linear-gradient(var(--table-background), var(--table-background));
}
.data-table-component.standard.sticky-last-column:not(.mobile-data-table) tbody tr:nth-child(2n) td:last-child {
	background-image:
		linear-gradient(var(--table-row-even-background), var(--table-row-even-background)),
		linear-gradient(var(--table-background), var(--table-background));
}


/* Notice */
.notice {
	display: block;
	padding: calc(15px - 0.2em) 15px;
	border-left: 3px solid var(--highlight-color);
	background: var(--notice-background);
	color: var(--notice-text);
	font-weight: 300;
	line-height: 1.2;
}
.notice:not(small) {
	font-size: 1rem;
}
.notice.info {
	border-left-color: var(--info-background);
	background: hsla(var(--info-background-h), var(--info-background-s), var(--info-background-l), 15%);
	color: var(--info-background);
}
.notice.help {
	border-left-color: var(--help-background);
	background: hsla(var(--help-background-h), var(--help-background-s), var(--help-background-l), 15%);
	color: var(--help-background);
}
.notice.warning {
	border-left-color: var(--warning-background);
	background: hsla(var(--warning-background-h), var(--warning-background-s), var(--warning-background-l), 15%);
	color: var(--warning-background);
}
.notice.error {
	border-left-color: var(--error-background);
	background: hsla(var(--error-background-h), var(--error-background-s), var(--error-background-l), 15%);
	color: var(--error-background);
}
.notice.success {
	border-left-color: var(--success-background);
	background: hsla(var(--success-background-h), var(--success-background-s), var(--success-background-l), 15%);
	color: var(--success-background);
}

.notice .notice-icon {
	float: left;
	font-size: 2.4em;
	margin-right: 10px;
}

.notice-card {
	background: var(--card-background);
	color: var(--card-text);
	line-height: 1.2;
	border-left: 4px solid var(--highlight-color);
	border-radius: var(--card-border-radius);
	box-shadow: var(--card-shadow);
	padding: 15px;
}
.notice-card.info {
	border-left-color: var(--info-background);
}
.notice-card.help {
	border-left-color: var(--help-background);
}
.notice-card.warning {
	border-left-color: var(--warning-background);
}
.notice-card.error {
	border-left-color: var(--error-background);
}
.notice-card.success {
	border-left-color: var(--success-background);
}


/* Page notice ------------------------------------------------------------------------------------------------------ */
.page-notice-wrapper {
	text-align: center;
	width: 720px;
	max-width: 100%;
	margin: 0 auto;
	padding: 0;
}
.page-notice-title-icon.icon {
	display: block;
	font-size: 5rem;
	color: var(--text-color-faded);
	opacity: 0.5;
	margin-top: var(--padding-section);
	margin-bottom: calc(0px - var(--padding-section));
}
.page-notice-title {
	font-size: 2rem;
	text-align: center;
	font-weight: 100;
	line-height: 1;
	color: var(--text-color-faded);
	margin-top: var(--padding-section);
	margin-bottom: calc(0px - var(--padding-section));
}
.page-notice {
	margin-top: calc(var(--padding-section) + .25em);
	text-align: center;
	font-size: 1.25rem;
	font-weight: 200;
	line-height: 1.25;
	color: var(--text-color-faded);
}


/* Label/value list */
.label-value-list {
	font-size: 1.25rem;
	display: flex;
	flex-flow: column wrap;
	overflow: auto;
}

.label-value-list .row {
	margin-bottom: 1em;
	padding-right: 2em;
	flex: 1 1 auto;
}
.label-value-list .row.custom {
	margin-bottom: 1em;
}
.label-value-list .row.custom:last-child {
	margin-bottom: 0;
}
.label-value-list .label,
.label-value-list .value
{
	display: inline;
}
.label-value-list .label {
	font-weight: 700;
	margin-right: 1em;
}
.label-value-list .value {
	font-weight: 500;
}

@media screen and (max-width: 640px) {
	.label-value-list .row {
		min-height: 0;
		margin-bottom: 1em;
	}
	.label-value-list .label,
	.label-value-list .value
	{
		display: block;
	}
}


/* Google reCaptcha */
.grecaptcha-badge {
	z-index: 99999;
}


/* Component list */
.component-list.default-style {
	
}
.component-list.default-style .component-list-item {
	border-bottom: 1px solid var(--border-color-main);
	display: flex;
	flex-flow: row nowrap;
	padding: 2rem 0;
	transition: background-color 150ms ease-in;
}
.component-list.default-style .component-list-item.last {
	border-bottom: none;
	padding-bottom: 60px;
}
.component-list.default-style .component-list-item .component-list-item-inner {
	flex: 0 1 100%;
	max-width: calc(var(--form-label-width) + var(--form-field-max-width));
}
.component-list.default-style .component-list-item .component-list-items-form-field {
	margin: -1px 0;
}
.component-list.default-style .component-list-item .component-list-item-remove {
	display: flex;
	flex-basis: 60px;
	flex-shrink: 0;
	align-items: center;
	justify-content: flex-end;
}
.component-list.default-style .component-list-item .component-list-item-remove .button,
.component-list.default-style .component-list-item .component-list-item-remove .button-component {
	height: 100%;
}

/* Editable component list */
.editable-component-list.default-style {

}
.editable-component-list.default-style .form-field .form-field-input {
	align-content: flex-start;
}
.editable-component-list.default-style .list-item {
	background-color: var(--background-color-main);
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	padding: calc(var(--padding-section) / 3);
	margin-bottom: calc(var(--padding-section) / 2);
	border-radius: var(--card-border-radius);
	transition: background-color .15s ease-out;
}
.editable-component-list.default-style .list-item:hover {
	background-color: var(--action-color-faded);
	transition: background-color .2s ease-in;
}
.editable-component-list.default-style .list-item .item-section {
	--form-field-max-width: none;

	margin: 0 calc(var(--padding-section) / 4) 0 0;
}
.editable-component-list.default-style .list-item .item-section .item-section-title {
	font-size: .75em;
	text-transform: uppercase;
	color: var(--text-color-faded);
	background-color: transparent;
}
.editable-component-list.default-style .list-item .item-section.delete-section {
	width: var(--input-height);
	flex: 0 0 auto;
	margin-right: 0;
}
.editable-component-list.default-style .list-item .item-section.delete-section .item-section-title {
	width: var(--input-height);
	visibility: hidden;
}
.editable-component-list.default-style .list-item .item-section.delete-section button,
.editable-component-list.default-style .list-item .item-section.delete-section .button {
	margin: 0;
	width: var(--input-height);
	height: 100%;
}
.editable-component-list.default-style .list-item .item-section.delete-section button:hover,
.editable-component-list.default-style .list-item .item-section.delete-section .button:hover {
	background-color: var(--error-background-hover) !important;
	border-color: var(--error-background-hover) !important;
	color: var(--error-text) !important;
}
.editable-component-list.default-style .list-item.with-errors .item-section.delete-section button,
.editable-component-list.default-style .list-item.with-errors .item-section.delete-section .button {
	height: calc(100% - 0.875rem);
}
.editable-component-list.default-style .new-item {
	display: flex;
	justify-content: flex-start;
}
.editable-component-list.default-style .new-item button {
	margin: 0;
	height: var(--input-height);
	font-size: var(--input-font-size);
	width: 100%;
	border-style: dashed;
	background: var(--background-color-secondary);
}

.editable-component-list.transparent-style .list-item {
	background-color: transparent;
	padding: 0;
	margin-bottom: calc(var(--padding-section) / 4);
}
.editable-component-list.transparent-style .list-item:hover {
	background-color: transparent;
}
.editable-component-list.transparent-style .list-item .item-section .item-section-title {
	font-size: 1em;
	text-transform: none;
	color: inherit;
}

.editable-component-list.compact-style .form-field {
	--input-border-radisu: 0;
}
.editable-component-list.compact-style .list-item .item-section.delete-section button,
.editable-component-list.compact-style .list-item .item-section.delete-section .button {
	border-radius: 0;
}
.editable-component-list.compact-style .list-item {
	margin: -1px 0 0 0;
}
.editable-component-list.compact-style .list-item.first {
	margin-top: 0;
}
.editable-component-list.compact-style .list-item .item-section {
	margin: 0 -1px 0 0;
}
.editable-component-list.compact-style .list-item .item-section .item-section-title {
	font-size: 1em;
	text-transform: none;
	color: inherit;
	margin-bottom: .25em;
}
.editable-component-list.compact-style .new-item {
	margin: -1px 0 0 0;
}
.editable-component-list.compact-style .new-item button {
	border-radius: 0;
	margin: 0;
}
.editable-component-list.compact-style .new-item span {
	display: none;
}
.editable-component-list.compact-style .new-item .icon {
	margin: 0;
}

@media screen and (max-width: 1279px) {
	.editable-component-list.transparent-style .list-item {
		flex-flow: column nowrap;
		position: relative;
		background: var(--background-color-secondary);
		padding: 0 calc(var(--input-height) + 10px + calc(var(--padding-section) / 2)) calc(var(--padding-section) / 2) calc(var(--padding-section) / 2);
		margin-bottom: calc(var(--padding-section) / 2);
	}
	.editable-component-list.transparent-style .list-item:hover {
		background: var(--background-color-secondary);
	}
	.editable-component-list.transparent-style .list-item .form-field {
		flex-flow: column nowrap;
	}
	.editable-component-list.transparent-style .list-item .form-field.label-position-none > .form-field-label {
		display: block;
	}
	.editable-component-list.transparent-style .list-item .form-field .form-field-label {
		margin-top: 1em;
		margin-bottom: 5px;
	}
	.editable-component-list.transparent-style .list-item .delete-section {
		position: absolute;
		top: 0;
		right: calc(var(--padding-section) / 2);
		height: calc(100% - calc(var(--padding-section) / 2));
	}
	.editable-component-list.default-style .list-item.with-errors .item-section.delete-section button,
	.editable-component-list.default-style .list-item.with-errors .item-section.delete-section .button {
		height: 100%;
	}
	.editable-component-list.default-style .new-item button {
		width: 100%;
	}
}

/* Tags */
.tag {
	display: inline-block;
	background: var(--tag-background);
	color: inherit;
	border-radius: 4px;
	padding: 0.25em 0.5em;
	margin: 0.25em 0.5em 0.25em 0;
}
.tag > .button-component,
.tag > button {
	padding: 0.25em 0.5em !important;
	margin: -0.25em -0.5em;
	font-size: inherit;
}
.tag > .button-component.right,
.tag > button.right {
	margin-left: .5em;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.tag > .button-component.left,
.tag > button.left {
	margin-right: .5em;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.tag.default {
	background-color: var(--default-background);
	color: var(--default-text);
}
.tag.action {
	background-color: var(--action-color-main);
	color: var(--action-color-contrast-main);
}
.tag.highlight {
	background-color: var(--highlight-color);
	color: var(--highlight-color-contrast);
}
.tag.info {
	background-color: var(--info-background);
	color: var(--info-text);
}
.tag.help {
	background-color: var(--help-background);
	color: var(--help-text);
}
.tag.success {
	background-color: var(--success-background);
	color: var(--success-text);
}
.tag.warning {
	background-color: var(--warning-background);
	color: var(--warning-text);
}
.tag.error {
	background-color: var(--error-background);
	color: var(--error-text);
}
.tag.outline {
	background-color: transparent;
	border: 1px solid var(--tag-background);
	color: var(--text-color-main);
}
.tag.outline.control {
	border: var(--form-control-border);
	background-color: var(--form-control-background);
	font: var(--font-main-input);
	font-size: var(--input-font-size);
	padding: .375rem .75rem;
}
.tag.outline.default {
	border-color: var(--default-background);
}
.tag.outline.action {
	border-color: var(--action-color-main);
}
.tag.outline.highlight {
	border-color: var(--highlight-color);
}
.tag.outline.info {
	border-color: var(--info-background);
}
.tag.outline.help {
	border-color: var(--help-background);
}
.tag.outline.success {
	border-color: var(--success-background);
}
.tag.outline.warning {
	border-color: var(--warning-background);
}
.tag.outline.error {
	border-color: var(--error-background);
}


/* Comments */
.comments-wrapper {
	--comment-min-width: 500px;
	--comment-max-width: 1200px;
	--comment-form-width: 400px;

	display: flex;
	flex-flow: row wrap;
}

.comments {
	flex: 1 1 calc(100% - var(--comment-form-width) - var(--padding-section));
	min-width: var(--comment-min-width);
	max-width: var(--comment-max-width);
	margin-right: var(--padding-section);
}
.comments p {
	margin: 0;
	padding: 0;
}
.comments .comment-wrapper {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	margin-bottom: calc(var(--padding-section) / 2);
	padding-bottom: calc(var(--padding-section) / 2);
	border-bottom: 2px solid var(--background-color-main);
}
.comments .comment-wrapper .comment-user-img {
	flex: 0 0 auto;
	width: 42px;
	height: 42px;
	border-radius: 100%;
	margin-right: 16px;
}
.comments .comment-wrapper .comment-user-img.icon {
	font-size: 42px;
}
.comments .comment {
	flex: 1 1 100%;
}
.comments .comment .comment-header {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	line-height: 1;
	margin-bottom: .5em;
	font-size: 1.25em;
}
.comments .comment .comment-header .comment-username {
	font-weight: bold;
}
.comments .comment .comment-header .comment-date {
	font-size: 0.65em;
	color: var(--highlight-color);
}
.comments .comment .comment-content {
	width: 100%;
	color: var(--text-color-subtle);
}
.comments .comment .comment-title {
	margin-bottom: .5em;
	font-weight: bold;
}
.comments .comment .comment-message {
	white-space: pre-wrap;
}
.comments .comment .comment-contact {
	margin-top: .5em;
	font-weight: bold;
}

.comment-form {
	flex: 0 0 var(--comment-form-width);
	padding: calc(var(--padding-section) / 1.5);
	background-color: var(--background-color-main);
	border-radius: 16px;
}
.comment-form .comment-form-inner {
	position: sticky;
	top: 80px;
	left: 0;
}
.comment-form button {
	width: 100%;
}

@media screen and (max-width: 640px) {
	.comments-wrapper {
		--comment-min-width: none;
		--comment-form-width: 100%;

		display: block;
	}
}

/* Separator */
.separator.with-shadow {
	box-shadow: 0 4px 4px -4px rgba(var(--card-shadow-rgb), 0.2);
}


/* Legend */
.legend-wrapper {
	margin-top: 1em;
	padding-top: .25em;
	border-top: 1px dashed var(--border-color-main);
}
.legend-label {
	color: var(--text-color-faded);
}
.legend-label sup strong {
	color: var(--text-color-main);
}