.message-preview-component.message-type-EMAIL {
	
}
.message-preview-component.message-type-EMAIL .screen .avatar {
	display: none;
}
.message-preview-component.message-type-EMAIL .screen .message {
	background: none;
	border: none;
	padding: 0 .5em;
}
.message-preview-component.message-type-EMAIL .message .time {
	display: none;
}