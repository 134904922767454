.wrapper {
	
}

.wrapper .inner {
	width: 420px;
}

@media screen and (max-width: 640px) {
	.wrapper .inner {
		width: 100%;
		min-width: 320px;
	}
}