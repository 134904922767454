.wrapper {
	width: 768px;
	max-width: 90vw;
}

.wrapper .logo {
	
}
.wrapper .logo > img {
	max-width: 100%;
	margin-bottom: 2rem;
}

.formWrapper {
	margin: 0 auto;
	background: var(--card-background);
	box-shadow: var(--card-shadow);
	color: var(--card-text);
	padding: var(--padding-section);
	border-radius: var(--card-border-radius);
}
.formWrapper .formTitle {
	font-size: 2rem;
	line-height: 1;
	font-weight: 600;
	margin-bottom: .5rem;
}
.formWrapper .formActions {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	gap: .5rem;
	margin-top: 1rem;
}
.formWrapper .formActions > button {
	flex: 1 1 calc(50% - .5rem);
	white-space: nowrap;
}


.messageWrapper {
	background: var(--card-background);
	box-shadow: var(--card-shadow);
	color: var(--card-text);
	padding: var(--padding-section);
	border-radius: var(--card-border-radius);
}
.messageWrapper small {
	display: block;
	color: var(--text-color-faded);
}
.messageWrapper .notice {
	text-align: center;
	padding: 0 0 20px;
	line-height: 1;
}
.messageWrapper .notice > i,
.messageWrapper .notice > p {
	margin: 0;
}
.messageWrapper .notice > p {
	margin-bottom: 0.25em;
}
.messageWrapper .notice > p:last-of-type {
	margin-bottom: 0;
}
.messageWrapper .actions {
	margin-top: 1.5rem;
}
.messageWrapper .actions button {
	width: 100%;
	margin-bottom: 1rem;
}
.messageWrapper .actions button:last-of-type {
	margin-bottom: 0;
}

@media screen and (max-width: 640px) {
	.wrapper,
	.messageWrapper {
		width: 100%;
		min-width: 300px;
	}
}