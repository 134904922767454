#messages-page #main-page-table th.column-messagetype .content {
	justify-content: center;
}

#messages-page #main-page-table .status .label-help-icon {
	color: inherit;
	opacity: .5;
}

#messages-page .simple-static-search-component .additional-search-buttons {
	padding: .5em;
	display: flex;
	flex-flow: row wrap;
	gap: .5em;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 1024px) {
	#messages-page .simple-static-search-component .additional-search-buttons > * {
		flex: 0 0 calc(50% - .5em);
	} 
}

@media screen and (max-width: 768px) {
	#messages-page #main-page-table > table > tbody > tr > td:first-of-type > .content > .text-center > div {
		display: inline;
	}
	#messages-page #main-page-table > table > tbody > tr > td:first-of-type > .content > .text-center > div:first-of-type {
		font-size: 1em !important;
		margin-right: .25em;
	}
}

@media screen and (max-width: 640px) {
	#messages-page .simple-static-search-component .additional-search-buttons > * {
		flex: 0 0 100%;
	}
}