.key-value-list-item-component.default-style {
	margin-bottom: var(--key-value-list-item-spacing);
}
.key-value-list-item-component.default-style:last-child {
	margin-bottom: 0;
}
.key-value-list-item-component.default-style > .key-value-wrapper {
	display: flex;
	flex-flow: row nowrap;
	flex: 1 1 100%;
}

.key-value-list-item-component.default-style .key-wrapper .key-label-inset,
.key-value-list-item-component.default-style .value-wrapper .value-label-inset {
	display: none;
	font-size: .875em;
	color: var(--form-control-placeholder-text);
	padding: calc(.375rem + .3em) .5rem 0;
	line-height: 1;
	position: relative;
	z-index: 1;
}

.key-value-list-item-component.default-style > div,
.key-value-list-item-component.default-style > .key-value-wrapper > div {
	margin-right: var(--key-value-list-item-spacing);
}
.key-value-list-item-component.default-style > .select-wrapper,
.key-value-list-item-component.default-style > .key-value-wrapper > .value-wrapper {
	margin-right: 0;
}

.key-value-list-item-component.default-style .key-wrapper {
	flex-basis: var(--key-value-list-key-column-width);
	min-width: var(--key-value-list-key-column-min-width);
}
.key-value-list-item-component.default-style .key-wrapper .select-input__menu {
	z-index: 1;
}
.key-value-list-item-component.default-style.not-selectable .key-wrapper {
	margin-left: var(--key-value-list-item-spacing);
}
.key-value-list-item-component.default-style .value-wrapper {
	flex-basis: var(--key-value-list-value-column-width);
	min-width: var(--key-value-list-value-column-min-width);
}
.key-value-list-item-component.default-style .remove-wrapper {
	flex: 0 0 36px;
	margin-right: calc(20px - var(--key-value-list-item-spacing));
}
.key-value-list-item-component.default-style .remove-wrapper .button-component {
	width: 100%;
	height: 100%;
	margin: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.key-value-list-item-component.default-style .form-field {
	margin-bottom: 0;
}
.key-value-list-item-component.default-style .form-field-input,
.key-value-list-item-component.default-style .form-control-wrapper,
.key-value-list-item-component.default-style .form-control {
	width: 100%;
	max-width: none;
}

.key-value-list-item-component.default-style .form-control[readonly],
.key-value-list-item-component.default-style .form-control.read-only {
	background-color: var(--form-control-label-background);
	color: var(--form-control-label-text);
	border-color: var(--form-control-label-background);
}

@media screen and (max-width: 640px) {
	.key-value-list-item-component.default-style {
		margin: calc(var(--key-value-list-item-spacing) * 4) 0;
	}
	.key-value-list-item-component.default-style:first-child {
		margin-top: 0;
	}
	.key-value-list-item-component.default-style:last-child {
		margin-bottom: 0;
	}
	.key-value-list-component.default-style.selectable > .form-wrapper {
		padding: calc(var(--key-value-list-item-spacing) * 4) 0;
	}
	.key-value-list-item-component.default-style > .key-value-wrapper {
		flex-flow: row wrap;
		gap: var(--key-value-list-item-spacing);
	}
	.key-value-list-item-component.default-style > .key-value-wrapper > div {
		flex: 0 0 100%;
		margin-right: 0;
	}
	.key-value-list-item-component.default-style .remove-wrapper {
		margin-right: calc(var(--key-value-list-item-spacing) * 4);
		position: sticky;
		top: 0;
		right: 0;
		z-index: 1;
		background: var(--form-control-background);
	}

	.key-value-list-item-component.default-style .form-control[readonly],
	.key-value-list-item-component.default-style .form-control.read-only {
		background-color: var(--form-control-background);
		color: var(--form-control-text);
		border-color: var(--form-control-background);
	}

	.key-value-list-item-component.default-style .key-wrapper .key-label-inset,
	.key-value-list-item-component.default-style .value-wrapper .value-label-inset {
		padding: .25em 0;
		display: block;
	}
}