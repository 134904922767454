.advanced-dropdown-content.default-style {
	background: var(--dropdown-background);
	border: var(--dropdown-border);
	-webkit-box-shadow: var(--dropdown-shadow);
	box-shadow: var(--dropdown-shadow);
	border-radius: var(--dropdown-border-radius);
	padding: var(--dropdown-content-padding);
}
.advanced-dropdown-content.default-style .advanced-dropdown-item {
	cursor: pointer;
	padding: 0.5em 0.75em;
	text-align: left;
	font-family: inherit;
	border-radius: var(--dropdown-item-border-radius);
	opacity: 1;
	margin: 0;
}
.advanced-dropdown-content.default-style .advanced-dropdown-item > .icon {
	margin-right: .5em;
}
.advanced-dropdown-content.default-style .advanced-dropdown-item:not(.button-component) {
	color: var(--dropdown-text);
	text-decoration: none;
	font-size: 1em;
}
.advanced-dropdown-content.default-style.no-padding .advanced-dropdown-item {
	border-radius: 0;
}
.advanced-dropdown-content.default-style .advanced-dropdown-item[data-group-label='true'],
.advanced-dropdown-content.default-style .advanced-dropdown-item[data-label='true'],
.advanced-dropdown-content.default-style .advanced-dropdown-item[data-disabled='true'] {
	cursor: default;
}
.advanced-dropdown-content.default-style .advanced-dropdown-item[data-group-label='true'] {
	color: var(--dropdown-text);
	text-decoration: none;
	padding: 0.5em 0.75em 0.25em;
	text-align: center;
	font-size: 1em;
	font-weight: 600;
}
.advanced-dropdown-content.default-style .advanced-dropdown-item.active {
	color: var(--action-color-main);
}
.advanced-dropdown-content.default-style .advanced-dropdown-item:not([data-group-label='true']):not([data-label='true']):not([data-disabled='true']):hover {
	background-color: var(--dropdown-background-hover);
	opacity: 1;
}

.advanced-dropdown-content.default-style .advanced-dropdown-separator {
	display: block;
	width: calc(100% - (.75em * 2));
	height: 1px;
	padding: 0;
	margin: .5em .75em;
	background-color: var(--border-color-main);
}