.import-customers-from-file .file-column-map .form-control-wrapper .input-toolbar .input-toolbar-button,
.import-customers-from-file .file-column-map .form-control .input-toolbar-button,
.import-customers-from-file .file-column-map .input-toolbar .input-toolbar-button-wrapper .button-component,
.import-customers-from-file .file-column-map .datepicker.form-control [class*="picker__button"],
.import-customers-from-file .file-column-map .select-input-component.standard .select-input__indicator,
.import-customers-from-file .file-column-map .select-input-component.form-control .select-input__indicator {
	font-size: 1rem;
}

.import-customers-from-file .select-input-component.standard .select-input__value-container,
.import-customers-from-file .select-input-component.form-control .select-input__value-container {
	padding: calc(.25rem - 4px) calc(.5rem - 2px);
}
.import-customers-from-file .file-input-component > .preview {
	padding: 0;
}
.import-customers-from-file .file-input-component > .preview .file-column-map {
	border: none;
}
#root .import-customers-from-file .key-value-list-item-component .key-wrapper .key-label-inset,
#root .import-customers-from-file .key-value-list-item-component .value-wrapper .value-label-inset {
	font-size: 12px;
}

@media screen and (max-width: 640px) {
	.import-customers-from-file .key-value-list-component .key-value-list-item-component:not(:last-of-type) {
		border-bottom-width: 2px !important;
	}
}