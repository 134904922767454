.wrapper {
	
}

.breadcrumbs .status {
	font-size: 0.75rem;
}

.wrapper .inner {
	display: flex;
	flex-flow: column nowrap;
	background-color: var(--card-background);
	box-shadow: var(--card-shadow);
}

.wrapper .separator,
.wrapper .separator {
	position: sticky;
	top: 69px;
	left: 0;
	z-index: 1;
	background: inherit;
	padding-top: 5px;
	min-height: 50px;
	border-bottom: var(--card-border);
	margin-bottom: calc(var(--padding-section) / 2);
	box-shadow: 0 4px 4px -4px rgba(var(--card-shadow-rgb), 0.2);
	font-weight: 600;
}
.wrapper .separator:not(.withToolbar) .title {
	margin-bottom: .25em;
}
.wrapper .separator.withToolbar {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-end;
	padding-bottom: .5rem;
	column-gap: 5px;
	row-gap: 10px;
}
.wrapper .separator.withToolbar.editTitle {
	background-color: var(--card-background-subtle);
}
.wrapper .separator.withToolbar.pingsTitle {
	background-color: var(--card-background);
}
.wrapper .separator.withToolbar .title {
	flex: 1 1 auto;
	padding-bottom: 0;
	display: flex;
	align-items: flex-end;
}
.wrapper .separator.withToolbar .title i {
	margin-bottom: .25em;
}
.wrapper .separator .dropdown,
.wrapper .separator .button {
	flex: 0 0 auto;
}
.wrapper .separator .dropdownButton,
.wrapper .separator .button {
	font: var(--font-main);
	font-size: .75rem;
	font-weight: normal;
	margin-top: 0;
	margin-bottom: 0;
}
.wrapper .separator .dropdownButton i {
	margin-right: 0;
}
.wrapper .separator .dropdownButtonValue {
	
}
@media screen and (max-width: 480px) {
	.wrapper .separator .dropdownButton {
		padding-left: .5em;
		padding-right: .5em;
	}
	.wrapper .separator .dropdownButtonValue {
		display: none;
	}
}

.wrapper .general,
.wrapper .pings {
	flex: 0 0 100%;
	padding: 10px 30px 15px;
}
.wrapper .general {
	background-color: var(--card-background-subtle);
	border-right: none;
	border-bottom: var(--card-border);
	border-bottom-style: dashed;
}
.wrapper .pings .ping {
	background: transparent;
	border-bottom: 1px solid var(--table-inner-border-color);
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	border-radius: 0;
	transition: all 0.1s ease-out;
	min-height: 56px;
	cursor: pointer;
}
.wrapper .pings .ping.readOnly {
	
}
.wrapper .pings .ping .actions {
	flex: 0 0 auto;
	background: none;
	padding: 0;
	border: none;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	gap: .5em;
}
.wrapper .pings .ping .actions button {
	transition: background-color 0.15s ease-in, color 0.15s ease-in;
	padding: 0.6em 1em;
}
.wrapper .pingInner {
	flex: 1 1 100%;
	padding: .5em 1em .5em 0;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	overflow: visible;
}
.wrapper .pingInner .basicInfo {
	flex: 1 1 200px;
	white-space: nowrap;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
}
.wrapper .ping .pingInner .basicInfo [data-tooltipped=""] {
	display: inline-block;
	margin-right: .5em;
}

.wrapper .ping .pingInner .basicInfo .identifier {
	display: inline-block;
	padding: .25em .5em;
	border-radius: 4px;
	font-size: .75em;
	line-height: 1;
}
.wrapper .ping.statusIN_PREPARATION .pingInner .basicInfo .identifier {
	background-color: var(--default-background);
	color: var(--default-text);
}
.wrapper .ping.statusPUBLISHED .pingInner .basicInfo .identifier {
	background-color: var(--success-background);
	color: var(--success-text);
}

.wrapper .pingInner {
	font-size: .875em;
}
.wrapper .pingInner .channels {
	flex: 1 1 100%;
	min-width: 0;
	text-align: center;
}
.wrapper .pingInner .channel:first-of-type > span > span {
	border-left: 4px solid var(--highlight-color);
	color: var(--highlight-color);
}
.wrapper .pingInner .channels .arrow {
	font-size: .75em;
	opacity: .25;
	margin: 0 .5em 0 0;
}
.wrapper .pingInner .channel {
	font-size: .875em;
}
.wrapper .pingInner .date {
	flex: 1 1 100px;
	text-align: right;
}

.wrapper .newButtonBottom {
	width: 100%;
	height: 50px;
	text-align: center;
	border-style: none;
	border-radius: 0;
	padding: .5em 0;
	font: var(--font-main);
	font-size: .875rem;
	margin-top: 0;
	margin-bottom: 1em;
	border-bottom: 1px solid var(--table-inner-border-color) !important;
}

@media screen and (min-width: 1680px) {
	.wrapper .pingInner {
		font-size: 1em;
	}
}

@media screen and (min-width: 1440px) {
	.wrapper .inner {
		flex-flow: row nowrap;
	}

	.wrapper .general {
		flex: 1 1 420px;
		position: sticky;
		align-self: flex-start;
		top: 69px;
		left: 0;
		border-bottom: none;
		border-right: var(--card-border);
		border-right-style: dashed;
	}

	.wrapper .pings {
		flex: 1 1 calc(100% - 420px - var(--padding-section));
	}
}

@media screen and (min-width: 1024px) {
	.wrapper .pings .ping:hover,
	.wrapper .newButtonBottom:hover {
		box-shadow: 
			inset 0 0 0 999em var(--table-row-highlight-background),
			.75em 0 0 0 var(--table-row-highlight-background),
			-.75em 0 0 0 var(--table-row-highlight-background);
		transition: all 0.1s ease-in;
	}
}

@media screen and (max-width: 768px) {
	.wrapper .separator,
	.wrapper .separator {
		position: relative;
		top: unset;
		left: unset;
		z-index: unset;
	}
}

@media screen and (max-width: 767px) {
	.wrapper .pingInner {
		font-size: 1em;
		flex-flow: row wrap;
		justify-content: flex-start;
		column-gap: .5em;
		padding-right: 0;
	}
	.wrapper .pingInner .basicInfo,
	.wrapper .pingInner .date {
		flex: 0 0 auto;
		text-align: left;
	}
	.wrapper .pingInner .basicInfo {
		display: block;
	}
	.wrapper .ping .pingInner .basicInfo .identifier {
		padding: .3em .5em;
		margin: .25em .5em .25em 0;
	}
	.wrapper .pingInner .date:before {
		content: '-';
		margin-right: .5em;
	}
	.wrapper .pingInner .channels {
		order: 999;
		flex: 0 0 100%;
		text-align: left;
		font-size: .875em;
	}
}

@media screen and (max-width: 480px) {
	.wrapper .pings {
		padding: 10px 20px 15px;
	}
	.wrapper .pingInner {
		padding-top: 1em;
		padding-bottom: 1em;
	}
	.wrapper .pings .ping {
		align-items: stretch;
	}
	.wrapper .pings .ping > .actions {
		padding-top: 1em;
		padding-bottom: 1em;
		margin-left: .5em;
	}
	.wrapper .pings .ping > .actions > [data-tooltipped],
	.wrapper .pings .ping > .actions > [data-tooltipped] > button {
		height: 100%;
	}
	.wrapper .ping:first-child .pingInner {
		padding-top: 0;
	}
	.wrapper .pingInner .channels {
		font-size: inherit;
		flex-flow: column nowrap;
		margin-top: .5em;
		position: relative;
		overflow: hidden;
	}
	.wrapper .pingInner .channel {
		display: block;
	}
	.wrapper .pingInner .channel:not(:first-child) {
		margin-left: 15px;
	}
	.wrapper .pingInner .channel > span,
	.wrapper .pingInner .channel > span > span {
		width: 100%;
	}
	.wrapper .pingInner .channels .arrow {
		display: none;
	}
	.wrapper .pingInner .channels:after {
		content: "";
		position: absolute;
		top: 1.75em;
		left: 0;
		width: 4px;
		height: 200px;
		background-color: var(--highlight-color);
	}
	.wrapper .pingInner .basicInfo {
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-start;
		width: 100%;
	}
	.wrapper .pingInner .count:before {
		content: "";
		font-family: 'FontAwesome';
		margin-right: .5em;
	}
	.wrapper .pingInner .date {
		flex: 1 1 100%;
	}
	.wrapper .pingInner .date:before {
		content: "";
		font-family: 'FontAwesome';
		margin-right: .5em;
	}
}