.wrapper {
	line-height: 1;
}

.item, 
.separator 
{
	margin-right: .75em;
}

@media screen and (max-width: 1024px) {
	.item,
	.separator
	{
		margin-right: .5em;
	}
}